import { Container, Col, Image, Row } from "react-bootstrap";
import LangComponent from "./language/LangComponent";
import React from "react";
import { Link } from "react-router-dom";
import "./NavbarWeb1Component.css";


const NavbarWeb1Component = () => {
  return (
    <Container className="p-md-4 py-3 navbar_1" fluid>
      <Row>
        <Col></Col>
        <Col xs={6} lg={8}>
          <Link to="/">
            <Image
              src="/img/fitness-logo-2.png"
              className="navbar-logo img-fluid d-block m-auto"
            />
          </Link>
        </Col>
        <Col xs={3} lg={2}>
          <LangComponent />
        </Col>
      </Row>
    </Container>
  );
};

export default NavbarWeb1Component;
