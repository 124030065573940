import * as ActionTypes from "./ActionTypes";

export const GymFavorites = (
  state = {
    isLoading: true,
    errMess: null,
    gymFavorites: [],
    favLoading: false,
    isFav: 0,
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.ADD_GYM_FAVORITES:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        gymFavorites: action.payload,
        favLoading: false,
        isFav: 0,
      };
    case ActionTypes.GYM_FAVORITES_LOADING:
      return { ...state, isLoading: true, errMess: null, gymFavorites: [] };
    case ActionTypes.GYM_FAVORITES_FAILED:
      return { ...state, isLoading: false, errMess: action.payload };
    case ActionTypes.UPDATE_GYM_FAVORITE_ADD:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        gymFavorites: [action.payload, ...state.gymFavorites],
        favLoading: false,
        isFav: 1,
      };
    case ActionTypes.UPDATE_GYM_FAVORITE_REMOVE:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        gymFavorites: state.gymFavorites.filter(
          (video) => video.id !== parseInt(action.payload.id, 10)
        ),
        favLoading: false,
        isFav: 0,
      };
    default:
      return state;
  }
};

export const HomeFavorites = (
  state = {
    isLoading: true,
    errMess: null,
    homeFavorites: [],
    favLoading: false,
    isFav: 0,
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.ADD_HOME_FAVORITES:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        homeFavorites: action.payload,
      };
    case ActionTypes.HOME_FAVORITES_LOADING:
      return { ...state, isLoading: true, errMess: null, homeFavorites: [] };
    case ActionTypes.HOME_FAVORITES_FAILED:
      return { ...state, isLoading: false, errMess: action.payload };
    case ActionTypes.HOME_FAVORITES_FAILED:
      return { ...state, isLoading: false, errMess: action.payload };
    case ActionTypes.UPDATE_HOME_FAVORITE_ADD:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        homeFavorites: [action.payload, ...state.homeFavorites],
        favLoading: false,
        isFav: 1,
      };
    case ActionTypes.UPDATE_HOME_FAVORITE_REMOVE:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        homeFavorites: state.homeFavorites.filter(
          (video) => video.id !== parseInt(action.payload.id, 10)
        ),
        favLoading: false,
        isFav: 0,
      };
    default:
      return state;
  }
};
