import React, { useState } from "react";
import { Col, Container, Image, Row, Tab, Tabs } from "react-bootstrap";
import EditProfileComponent from "./EditProfileComponent";
import UploadComponent from "./UploadComponent";
import ModalComponent from "../../modals/ModalComponent";
import { Trans, useTranslation } from "react-i18next";
import { FiEdit } from "react-icons/fi";
import { BsFillPlusSquareFill } from "react-icons/bs";
import "./UserDataComponent.css";

const UserDataComponent = (props) => {
  const { t } = useTranslation();
  const [key, setKey] = useState("uploaded");
  const [openEdit, setEditOpen] = useState(false);
  const [openImage, setImageOpen] = useState(false);

  const onOpenEditModal = () => {
    setEditOpen(!openEdit);
  };
  const onOpenImageModal = () => {
    setImageOpen(!openImage);
  };
  return (
    <Container className="userDataContainer  px-0 " fluid="md">
      {props.isLoading ? (
        <div></div>
      ) : (
        <Col xs={12} className="bg-secondary-dark p-3">
          <Row noGutters>
            <Col xs={3} md={2} className="m-auto">
              <div>
                <Image
                  src={
                    props.userData.image
                      ? process.env.REACT_APP_MEDIA_BASE_URL +
                        props.userData.image
                      : "/img/profile.png"
                  }
                  className="user-img m-auto d-block"
                />
                <BsFillPlusSquareFill
                  className="edit-img"
                  onClick={() => onOpenImageModal()}
                />
              </div>
            </Col>
            <Col xs={9} md={10} className="m-auto">
              <h5 className="fs-md text-white">
                {props.userData.username && props.userData.username !== "null"
                  ? props.userData.username
                  : t("userForm.username") + props.userData.id}
              </h5>
              <h3 className="fs-xm m-auto">{props.userData.msisdn}</h3>
            </Col>
          </Row>
          <Col xs={12} className="edit-btn color-primary-light">
            <span className="fs-sm" onClick={() => onOpenEditModal()}>
              <Trans i18nKey="profile.edit" />
              <FiEdit className="fs-xm mx-2" />
            </span>
          </Col>
        </Col>
      )}
      <EditProfileComponent
        updateData={props.updateData}
        open={openEdit}
        toggleModal={onOpenEditModal}
      />
      <ModalComponent
        open={openImage}
        toggleModal={onOpenImageModal}
        content={
          <UploadComponent
            updateImage={props.updateData}
            type="image"
            accept="image/x-png,image/gif,image/jpeg"
            title="upload.title_image"
            // description="upload.description_image"
          />
        }
      />
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="fs-sm justify-content-center bg-secondary-xlight p-lg-3"
      >
        <Tab eventKey="uploaded" title={<Trans i18nKey="profile.uploaded" />}>
          {props.uploaded}
        </Tab>
        <Tab eventKey="home" title={<Trans i18nKey="sections.section1" />}>
          {props.homeFav}
        </Tab>
        <Tab eventKey="gym" title={<Trans i18nKey="sections.section2" />}>
          {props.gymFav}
        </Tab>
      </Tabs>
    </Container>
  );
};

export default UserDataComponent;
