import { Container, Col, Image, Row } from "react-bootstrap";
import LangComponent from "./language/LangComponent";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import ItemsCarousel from "react-items-carousel";
import { CgProfile } from "react-icons/cg";
import { Trans } from "react-i18next";
import "./NavbarMob2Component.css";

const NavbarMob2Component = () => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  return (
    <Container className="py-3 navBarMob2" fluid>
      <Row>
        <Col>
          <Link to="/profile">
            <CgProfile className="fs-xl" />
          </Link>
        </Col>
        <Col xs={6} md={8} className="text-center">
          <Link to="/">
            <Image src="/img/fitness-logo-2.png" className="navbar-logo img-fluid" />
          </Link>
        </Col>
        <Col xs={3} md={2}>
          <LangComponent />
        </Col>
      </Row>
      <div className="mt-3">
        <div className="slider">
          <div className="slides">
            <div id="slide-1">
              <Link className="fs-xm" to="/">
                <Trans i18nKey="navbar.home" />
              </Link>
            </div>
            <div id="slide-2">
              <Link className="fs-xm" to="/gym-workout">
                <Trans i18nKey="sections.section2" />
              </Link>
            </div>
            <div id="slide-3">
              <Link className="fs-xm" to="/home-workout">
                <Trans i18nKey="sections.section1" />
              </Link>
            </div>
            <div id="slide-4">
              <Link className="fs-xm" to="/aly-mazhar-tips">
                <Trans i18nKey="sections.section7" />
              </Link>
            </div>
            <div id="slide-5">
              <Link className="fs-xm" to="/ask">
                <Trans i18nKey="sections.section3" />
              </Link>
            </div>
            <div id="slide-6">
              <Link className="fs-xm" to="/questions">
                <Trans i18nKey="sections.section4" />
              </Link>
            </div>
            <div id="slide-7">
              <Link className="fs-xm" to="/nutrition-tips">
                <Trans i18nKey="sections.section5" />
              </Link>
            </div>
            {/* <div id="slide-7">
              <Link className="fs-xm" to="/challenges">
                <Trans i18nKey="navbar.challenges" />
              </Link>
            </div> */}
            {/* <div id="slide-8">
              <Link className="fs-xm" to="/ranking">
                <Trans i18nKey="navbar.competition ranking" />
              </Link>
            </div> */}
          </div>
        </div>
        <ItemsCarousel
          requestToChangeActive={setActiveItemIndex}
          activeItemIndex={activeItemIndex}
          numberOfCards={3}
        ></ItemsCarousel>
      </div>
    </Container>
  );
};

export default NavbarMob2Component;
