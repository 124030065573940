import { Helmet } from "react-helmet";
import React from "react";

const Seo = ({ title, description, pathSlug, keywords }) => {
  const url = process.env.PUBLIC_URL + pathSlug;
  return (
    <Helmet
      title={title}
      meta={[
        {
          name: "description",
          content: description,
        },
        // {
        //   name: "keywords",
        //   content: keywords.join(),
        // },
      ]}
      links={[
        {
          rel: "canonical",
          href: url,
        },
      ]}
    />
  );
};
export default Seo;
