import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { GoCalendar } from "react-icons/go";
import { convertDate } from "../../globalFn";
import { Trans, useTranslation } from "react-i18next";
import "./BannerWebComponent.css";

const BannerWebComponent = (props) => {
  var today = new Date();
  const { i18n } = useTranslation();

  return (
    <Container fluid className="banner-container bg-secondary-dark">
      <Container className="py-3">
        <Col>
          <Row>
            <Col xs={9} className="p-0">
              <h4 className="color-primary-light text-uppercase fs-xxm">
                {props.title}
              </h4>
              <p className="fs-xs color-tertiary-xlight m-auto">
                {props.subTitle}
              </p>
            </Col>
            <Col className="p-0 m-auto">
              {props.show ? (
                <div className="d-flex">
                  <GoCalendar className="fs-xxl color-primary-light " />
                  <div className="mx-1">
                    <p className="fs-xs color-primary-light m-auto">
                      <Trans i18nKey="banner.title" />
                    </p>
                    <p className="fs-sm color-primary-light m-auto">
                      {convertDate(today, i18n.language)}
                    </p>
                  </div>
                </div>
              ) : null}
            </Col>
          </Row>
        </Col>
      </Container>
    </Container>
  );
};

export default BannerWebComponent;
