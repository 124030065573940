import React, { useState } from "react";
import StarRatings from "react-star-ratings";
import { Container, Image } from "react-bootstrap";
import ModalComponent from "../modals/ModalComponent";
import { AiFillStar } from "react-icons/ai";
import { FaRegCheckCircle } from "react-icons/fa";

import { Trans, useTranslation } from "react-i18next";
import "./ShareBtnComponent.css";
import "./RatingIconComponent.css";

const RatingIconComponent = (props) => {
  const { i18n, t } = useTranslation();
  const [openRate, setRateOpen] = useState(false);
  const [userRate, setUserRate] = useState(props.userVote);

  const onOpenRatingModal = () => {
    setRateOpen(!openRate);
  };

  return (
    <div
      className={`shareContainer text-white p-0 fs-xs ${
        i18n.language === "en" ? " ml-auto" : "mr-auto"
      }`}
    >
      <div
        className="basic fs-xs d-block m-1"
        onClick={() => onOpenRatingModal()}
      >
        <AiFillStar
          className={`fs-xxm basic ${
            i18n.language === "en" ? " mr-1" : "ml-1"
          }`}
        />
        <Trans i18nKey="btn.rate" />
      </div>
      <ModalComponent
        content={
          props.rateState.rate.status === 200 &&
          props.rateState.rate.message === "Success" ? (
            <Container
              style={{ background: "rgb(33, 33, 33)" }}
              className="p-0 border-radius-10 text-center p-5"
            >
              <div className="text-center">
                <FaRegCheckCircle className="fs-xxl color-primary-light" />
                <p className="text-center my-3 fs-xxm">
                  <Trans i18nKey="rating.success" />
                </p>
              </div>
            </Container>
          ) : (
            <Container
              style={{ background: "rgb(33, 33, 33)" }}
              className="p-0 border-radius-10 text-center p-5"
            >
              <h5 className="text-center text-white fs-xxm">
                <Trans i18nKey="rating.title" />
              </h5>
              <Image
                src={
                  props.userImg === null
                    ? "/img/profile.png"
                    : process.env.REACT_APP_MEDIA_BASE_URL + props.userImg
                }
                className="img-fluid p-2 user-img"
              />
              <p className="text-center m-auto fs-xm">{props.compTitle}</p>
              <p className="text-center text-white fs-md">
                {props.userName && props.userName !== "null"
                  ? props.userName
                  : t("userForm.username") + props.userId}
              </p>
              <StarRatings
                rating={userRate}
                starDimension="30px"
                starHoverColor="#7ebd19"
                starSpacing="5px"
                starRatedColor="#7ebd19"
                changeRating={(rating) => (
                  props.addRate(props.compId, rating), setUserRate(rating)
                )}
              />
            </Container>
          )
        }
        open={openRate}
        toggleModal={onOpenRatingModal}
      />
    </div>
  );
};

export default RatingIconComponent;
