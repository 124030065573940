import * as ActionTypes from "./ActionTypes";

export const GymWorkout = (
  state = {
    isLoading: true,
    errMess: null,
    gymWorkout: [],
    doneLoading: false,
    isDone: 0,
    progressBar: 0,
    favLoading: false,
    isFav: 0,
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.ADD_GYM_WORKOUT:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        gymWorkout: action.payload,
        progressBar: Math.round(
          (action.payload.filter((video) => video.done === 1).length /
            action.payload.filter((video) => video.type === "workout").length) *
            100
        ),
      };
    case ActionTypes.GYM_WORKOUT_LOADING:
      return { ...state, isLoading: true, errMess: null, gymWorkout: [] };
    case ActionTypes.GYM_WORKOUT_FAILED:
      return { ...state, isLoading: false, errMess: action.payload };
    case ActionTypes.UPDATE_GYM_DONE_LOADING:
      return { ...state, isLoading: false, errMess: null, doneLoading: true };
    case ActionTypes.UPDATE_GYM_DONE:
      let doneVal = (state.gymWorkout.filter(
        (video) => video.id === parseInt(action.payload, 10)
      )[0].done = +!state.gymWorkout.filter(
        (video) => video.id === parseInt(action.payload, 10)
      )[0].done);
      return {
        ...state,
        isLoading: false,
        errMess: null,
        doneLoading: false,
        isDone: doneVal,
        progressBar: Math.round(
          (state.gymWorkout.filter((video) => video.done === 1).length /
            state.gymWorkout.filter((video) => video.type === "workout")
              .length) *
            100
        ),
      };
    case ActionTypes.UPDATE_GYM_WORKOUTS_FAVORITE_LOADING:
      return { ...state, isLoading: false, errMess: null, favLoading: true };
    case ActionTypes.UPDATE_GYM_WORKOUTS_FAVORITE:
      let favVal = (state.gymWorkout.filter(
        (video) => video.id === parseInt(action.payload, 10)
      )[0].favorite = +!state.gymWorkout.filter(
        (video) => video.id === parseInt(action.payload, 10)
      )[0].favorite);
      return {
        ...state,
        isLoading: false,
        errMess: null,
        favLoading: false,
        isFav: favVal,
      };

    default:
      return state;
  }
};
