import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function HomeMobComponentLoader() {
  return (
    <Container className="homeMobiContainer">
      <SkeletonTheme color="#202020" highlightColor="#444">
        <div className="mt-4">
          <p className="text-center">
            <Skeleton count={1} />
            <Skeleton count={1} width={100} />
          </p>
          <Card className="my-3">
            <Card.Body className="">
              <Card.Title className="fs-16 color-7EBD19">
                <Row>
                  <Col xs={10}>
                    <Skeleton count={1} />
                  </Col>
                  <Col className="text-center  fs-22 color-7EBD19">
                    <Skeleton circle={true} width={10} height={10} />
                  </Col>
                </Row>
              </Card.Title>
              <Card.Text className="fs-12">
                <Skeleton count={2} />
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="my-3">
            <Card.Body className="">
              <Card.Title className="fs-16 color-7EBD19">
                <Row>
                  <Col xs={10}>
                    <Skeleton count={1} />
                  </Col>
                  <Col className="text-center  fs-22 color-7EBD19">
                    <Skeleton circle={true} width={10} height={10} />
                  </Col>
                </Row>
              </Card.Title>
              <Card.Text className="fs-12">
                <Skeleton count={2} />
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="my-3">
            <Card.Body className="">
              <Card.Title className="fs-16 color-7EBD19">
                <Row>
                  <Col xs={10}>
                    <Skeleton count={1} />
                  </Col>
                  <Col className="text-center  fs-22 color-7EBD19">
                    <Skeleton circle={true} width={10} height={10} />
                  </Col>
                </Row>
              </Card.Title>
              <Card.Text className="fs-12">
                <Skeleton count={2} />
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="my-3">
            <Card.Body className="">
              <Card.Title className="fs-16 color-7EBD19">
                <Row>
                  <Col xs={10}>
                    <Skeleton count={1} />
                  </Col>
                  <Col className="text-center  fs-22 color-7EBD19">
                    <Skeleton circle={true} width={10} height={10} />
                  </Col>
                </Row>
              </Card.Title>
              <Card.Text className="fs-12">
                <Skeleton count={2} />
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </SkeletonTheme>
    </Container>
  );
}
