import React from "react";
import { Container, Col, Row, Accordion, Card } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function WorkoutMobiLoader() {
  return (
    <Container className="workOutMobi p-0">
      <SkeletonTheme color="#202020" highlightColor="#444">
        <Row noGutters>
          <Col xs={12}>
            <Accordion defaultActiveKey="0" className="m-2">
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="1" className="p-2">
                  <h6 className="fs-16 color-7EBD19">
                    <Skeleton count={1} width={200} />
                  </h6>
                  <p className="fs-14 mb-1">
                    <Skeleton count={2} />
                  </p>
                </Accordion.Toggle>
                <div className="d-flex">
                  <Skeleton
                    circle={true}
                    width={30}
                    height={30}
                    className="mx-1"
                  />
                  <Skeleton circle={true} width={30} height={30} />
                  <div className="mobi-arrow p-1">
                    <Skeleton circle={true} width={30} height={30} />
                  </div>
                </div>
              </Card>
            </Accordion>
            <Accordion defaultActiveKey="0" className="m-2">
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="1" className="p-2">
                  <h6 className="fs-16 color-7EBD19">
                    <Skeleton count={1} />
                  </h6>
                  <p className="fs-14 mb-1">
                    <Skeleton count={2} />
                  </p>
                </Accordion.Toggle>
                <div className="d-flex">
                  <Skeleton
                    circle={true}
                    width={30}
                    height={30}
                    className="mx-1"
                  />
                  <Skeleton circle={true} width={30} height={30} />
                  <div className="mobi-arrow p-1">
                    <Skeleton circle={true} width={30} height={30} />
                  </div>
                </div>
              </Card>
            </Accordion>
            <Accordion defaultActiveKey="0" className="m-2">
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="1" className="p-2">
                  <h6 className="fs-16 color-7EBD19">
                    <Skeleton count={1} />
                  </h6>
                  <p className="fs-14 mb-1">
                    <Skeleton count={2} />
                  </p>
                </Accordion.Toggle>
                <div className="d-flex">
                  <Skeleton
                    circle={true}
                    width={30}
                    height={30}
                    className="mx-1"
                  />
                  <Skeleton circle={true} width={30} height={30} />
                  <div className="mobi-arrow p-1">
                    <Skeleton circle={true} width={30} height={30} />
                  </div>
                </div>
              </Card>
            </Accordion>
          </Col>
        </Row>
      </SkeletonTheme>
    </Container>
  );
}
