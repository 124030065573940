import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Button, Container, ButtonGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Seo from "../components/Seo";
import NavbarWeb2Component from "../components/navbar/NavbarWeb2Component";
import NavbarMob2Component from "../components/navbar/NavbarMob2Component";
import BannerWebComponent from "../components/banner/BannerWebComponent";
import BannerMobComponent from "../components/banner/BannerMobComponent";
import VideoCardWebComponent from "../components/videos/VideoCardWebComponent";
import FooterComponent from "../components/footer/FooterComponent";

const mapStateToProps = (state) => {
  return { videos: state.alyMazharTips };
};

const AlyMazharTips = (props) => {
  const { t } = useTranslation();
  return (
    <div>
      <Seo
        title={` ${t("title")} | ${t("sections.section7")} `}
        description={t("sections.description7")}
        pathSlug="nutrition-episodes"
      />

      <div className="d-none d-xl-block">
        <NavbarWeb2Component />
        <BannerWebComponent
          title={<Trans i18nKey="sections.section7" />}
          subTitle={<Trans i18nKey="sections.description7" />}
        />
      </div>
      <div className="d-xl-none">
        <NavbarMob2Component />
        <BannerMobComponent
          title={<Trans i18nKey="sections.section7" />}
          subTitle={<Trans i18nKey="sections.description7" />}
        />
      </div>
      <VideoCardWebComponent
        type="amt"
        colXsSize={12}
        colMdSize={6}
        colLgSize={4}
        isLoading={props.videos.isLoading}
        errMess={props.videos.errMess}
        videos={props.videos.alyMazharTips}
      />

      <FooterComponent />
    </div>
  );
};
export default connect(mapStateToProps)(AlyMazharTips);
