import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import Seo from "../components/Seo";
import HomeWebComponent from "../components/home/HomeWebComponent";
import HomeMobComponent from "../components/home/HomeMobComponent";
import NavbarWeb1Component from "../components/navbar/NavbarWeb1Component";
import FooterComponent from "../components/footer/FooterComponent";

const HomeComponent = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Seo
        title={` ${t("title")} | ${t("navbar.home")}`}
        description={t("intro")}
        pathSlug=""
      />
      <NavbarWeb1Component />
      <div className="d-none d-xl-block">
        <Container className="intro-section" fluid>
          <HomeWebComponent />
        </Container>
      </div>
      <div className="d-xl-none">
        <HomeMobComponent />
      </div>
      <FooterComponent />
    </div>
  );
};

export default HomeComponent;
