import React, { useState, useCallback } from "react";
import { Container, Card, Row, Col, Image } from "react-bootstrap";
import { GoCalendar } from "react-icons/go";
import { convertDate } from "../../globalFn";
import ArticleCardComponentLoader from "./ArticleCardComponentLoader";
import ModalComponent from "../modals/ModalComponent";
import { Trans, useTranslation } from "react-i18next";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import "./ArticleCardComponent.css";

const ArticleCardComponent = ({
  articles,
  isLoading,
  errMess,
  loadMorePerPage,
  pageNo,
}) => {
  const { i18n } = useTranslation();
  const [openArticle, setArticleOpen] = useState(false);
  const [articleText, setArticleText] = useState();
  const [loadMore, setLoadMore] = useState(false);

  const onOpenArticleModal = (articleText) => {
    setArticleText(articleText);
    setArticleOpen(!openArticle);
  };

  const handleOnDocumentBottom = useCallback(() => {
    setLoadMore(true);
    loadMorePerPage("TIPS", ++pageNo, true);
  }, []);
  useBottomScrollListener(handleOnDocumentBottom);

  if (isLoading || errMess) {
    return <ArticleCardComponentLoader />;
  } else {
    return (
      <Container className="articlesCardContainer" fluid="lg">
        <Row>
          {articles.map((article, index) => (
            <Col xs={12} lg={6} key={index}>
              <Card
                className="border-radius-10 my-2"
                onClick={() =>
                  onOpenArticleModal(
                    i18n.language === "en"
                      ? article.descriptionEn
                      : article.description
                  )
                }
              >
                <Row noGutters>
                  <Col xs={12} md={5}>
                    <Card.Img
                      variant="top border-radius-16 p-1"
                      src="/img/intro1.png"
                    />
                  </Col>
                  <Col className="m-auto">
                    <Card.Body className="p-2">
                      <Card.Text className="fs-md color-tertiary">
                        {i18n.language === "en"
                          ? article.descriptionEn.substring(0, 210) + "..."
                          : article.description.substring(0, 210) + "..."}
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                      <label className="fs-sm color-tertiary-dark float-right">
                        <GoCalendar className="fs-md color-tertiary-dark mr-1" />
                        {convertDate(article.date, i18n.language)}
                      </label>
                    </Card.Footer>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}

          <p
            className={`fs-md w-50 mx-auto text-center my-3 color-primary-light ${
              loadMore ? "d-block" : "d-none"
            }`}
          >
            <Trans i18nKey="subscription.subLoading" />
          </p>
          <ModalComponent
            content={
              <Container
                style={{ background: "rgb(33, 33, 33)" }}
                className="p-0 border-radius-10"
              >
                <Row noGutters>
                  <Col xs={12} lg={5}>
                    <Image
                      src="/img/intro1.png"
                      className="img-fluid p-2 border-radius-16"
                    />
                  </Col>
                  <Col className="m-auto">
                    <p className="m-2">{articleText}</p>
                  </Col>
                </Row>
              </Container>
            }
            open={openArticle}
            toggleModal={onOpenArticleModal}
          />
        </Row>
      </Container>
    );
  }
};

export default ArticleCardComponent;
