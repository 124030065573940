import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "./WorkoutWebComponent.css";

export default function WorkoutWebLoader() {
  return (
    <div>
      <Container fluid className="workOutWeb p-0">
        <SkeletonTheme color="#202020" highlightColor="#444">
          <Skeleton height={20} />
          <Row noGutters>
            <Col xs={7}></Col>
            <Col className="playlist-container">
              <ul className="list-group">
                <li className="list-group-item mb-1 d-flex">
                  <Col xs={10}>
                    <h6 className="m-auto">
                      <Skeleton count={1} />
                      <Skeleton count={1} />
                    </h6>
                  </Col>
                  <Col xs={2} className="d-flex m-auto p-0 iconContainer">
                    <Skeleton
                      circle={true}
                      height={30}
                      width={30}
                      className="mx-2"
                    />
                    <Skeleton
                      circle={true}
                      height={30}
                      width={30}
                      className="m-auto"
                    />
                  </Col>
                </li>
                <li className="list-group-item mb-1 d-flex">
                  <Col xs={10}>
                    <h6 className="m-auto">
                      <Skeleton count={1} />
                      <Skeleton count={1} />
                    </h6>
                  </Col>
                  <Col xs={2} className="d-flex m-auto p-0 iconContainer">
                    <Skeleton
                      circle={true}
                      height={30}
                      width={30}
                      className="mx-2"
                    />
                    <Skeleton
                      circle={true}
                      height={30}
                      width={30}
                      className="m-auto"
                    />
                  </Col>
                </li>
                <li className="list-group-item mb-1 d-flex">
                  <Col xs={10}>
                    <h6 className="m-auto">
                      <Skeleton count={1} />
                      <Skeleton count={1} />
                    </h6>
                  </Col>
                  <Col xs={2} className="d-flex m-auto p-0 iconContainer">
                    <Skeleton
                      circle={true}
                      height={30}
                      width={30}
                      className="mx-2"
                    />
                    <Skeleton
                      circle={true}
                      height={30}
                      width={30}
                      className="m-auto"
                    />
                  </Col>
                </li>
                <li className="list-group-item mb-1 d-flex">
                  <Col xs={10}>
                    <h6 className="m-auto">
                      <Skeleton count={1} />
                      <Skeleton count={1} />
                    </h6>
                  </Col>
                  <Col xs={2} className="d-flex m-auto p-0 iconContainer">
                    <Skeleton
                      circle={true}
                      height={30}
                      width={30}
                      className="mx-2"
                    />
                    <Skeleton
                      circle={true}
                      height={30}
                      width={30}
                      className="m-auto"
                    />
                  </Col>
                </li>
                <li className="list-group-item mb-1 d-flex">
                  <Col xs={10}>
                    <h6 className="m-auto">
                      <Skeleton count={1} />
                      <Skeleton count={1} />
                    </h6>
                  </Col>
                  <Col xs={2} className="d-flex m-auto p-0 iconContainer">
                    <Skeleton
                      circle={true}
                      height={30}
                      width={30}
                      className="mx-2"
                    />
                    <Skeleton
                      circle={true}
                      height={30}
                      width={30}
                      className="m-auto"
                    />
                  </Col>
                </li>
                <li className="list-group-item mb-1 d-flex">
                  <Col xs={10}>
                    <h6 className="m-auto">
                      <Skeleton count={1} />
                      <Skeleton count={1} />
                    </h6>
                  </Col>
                  <Col xs={2} className="d-flex m-auto p-0 iconContainer">
                    <Skeleton
                      circle={true}
                      height={30}
                      width={30}
                      className="mx-2"
                    />
                    <Skeleton
                      circle={true}
                      height={30}
                      width={30}
                      className="m-auto"
                    />
                  </Col>
                </li>
                <li className="list-group-item mb-1 d-flex">
                  <Col xs={10}>
                    <h6 className="m-auto">
                      <Skeleton count={1} />
                      <Skeleton count={1} />
                    </h6>
                  </Col>
                  <Col xs={2} className="d-flex m-auto p-0 iconContainer">
                    <Skeleton
                      circle={true}
                      height={30}
                      width={30}
                      className="mx-2"
                    />
                    <Skeleton
                      circle={true}
                      height={30}
                      width={30}
                      className="m-auto"
                    />
                  </Col>
                </li>
                <li className="list-group-item mb-1 d-flex">
                  <Col xs={10}>
                    <h6 className="m-auto">
                      <Skeleton count={1} />
                      <Skeleton count={1} />
                    </h6>
                  </Col>
                  <Col xs={2} className="d-flex m-auto p-0 iconContainer">
                    <Skeleton
                      circle={true}
                      height={30}
                      width={30}
                      className="mx-2"
                    />
                    <Skeleton
                      circle={true}
                      height={30}
                      width={30}
                      className="m-auto"
                    />
                  </Col>
                </li>
              </ul>
            </Col>
          </Row>
        </SkeletonTheme>
      </Container>
    </div>
  );
}
