import React from "react";
import { Row, Col, Card, Button, Container } from "react-bootstrap";
import { convertDate } from "../../globalFn";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import UploadBtnComponent from "../buttons/UploadBtnComponent";
import CompetitionComponentLoader from "./CompetitionComponentLoader";
import "./CompetitionComponent.css";

const CompetitionComponent = (props) => {
  const { i18n } = useTranslation();
  if (props.isLoading || props.errMess) {
    return <CompetitionComponentLoader />;
  } else {
    return (
      <Container className="competitionContainer" fluid="lg">
        <Row>
          {props.videos.map((challenge, index) => (
            <Col xs={12} key={challenge.id}>
              <Card className="border-radius-10 my-3">
                <Row noGutters>
                  <Col xs={12} md={5}>
                    <Link to={`/video/challenge/${challenge.id}`}>
                      <div className="play-btn box-shadow"></div>
                      <Card.Img
                        variant="top border-radius-16 p-1"
                        src={
                          process.env.REACT_APP_MEDIA_BASE_URL +
                          challenge.videoCover
                        }
                      />
                    </Link>
                  </Col>
                  <Col className="m-auto">
                    <Card.Body className="mx-2">
                      <Card.Title className="fs-xm text-uppercase color-tertiary-light">
                        <Trans i18nKey="competition.challenge" />
                        <span className="color-primary-light fs-xxl font-weight-bold mx-2">
                          {index + 1}
                        </span>
                      </Card.Title>
                      <small className="color-tertiary-dark fs-sm">
                        {convertDate(challenge.fromDate, i18n.language)}
                      </small>
                      <Card.Title className="text-capitalize fs-xm  color-primary-light">
                        {i18n.language === "en"
                          ? challenge.titleEn
                          : challenge.titleAr}
                      </Card.Title>
                      <Card.Text className="fs-md color-tertiary">
                        {i18n.language === "en"
                          ? challenge.descriptionEn
                          : challenge.descriptionAr}
                      </Card.Text>
                      <Row className="justify-content-end mb-2 mb-lg-auto">
                        <Col xs={6}>
                          <Button
                            className="fs-sm secondary-btn border-radius-8  py-md-2 px-md-2 w-100 btn btn-primary"
                            as={Link}
                            to="/users/challenges"
                          >
                            <Trans i18nKey="btn.browse" />
                          </Button>
                        </Col>
                        <Col xs={6}>
                          <UploadBtnComponent
                            challengeId={challenge.id}
                            trials={
                              challenge.trials === undefined
                                ? 2
                                : challenge.trials
                            }
                            approved={challenge.approved}
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    );
  }
};

export default CompetitionComponent;
