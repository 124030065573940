import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Seo from "../components/Seo";
import NavbarWeb2Component from "../components/navbar/NavbarWeb2Component";
import NavbarMob2Component from "../components/navbar/NavbarMob2Component";
import BannerWebComponent from "../components/banner/BannerWebComponent";
import BannerMobComponent from "../components/banner/BannerMobComponent";
import WorkoutWebComponent from "../components/workouts/WorkoutWebComponent";
import WorkoutMobComponent from "../components/workouts/WorkoutMobComponent";
import FooterComponent from "../components/footer/FooterComponent";
import { addDone, handleFavorite } from "../redux/ActionCreators";

const mapStateToProps = (state) => {
  return {
    gymWorkout: state.gymWorkout,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addDone: (video) => dispatch(addDone(video)),
  handleFavorite: (video) => dispatch(handleFavorite(video)),
});

const GymWorkout = (props) => {
  const { t } = useTranslation();

  return (
    <div>
      <Seo
        title={` ${t("title")} | ${t("sections.section2")}`}
        description={t("sections.description2")}
        pathSlug="gym-workout"
      />

      <div className="d-none d-xl-block">
        <div>
          <NavbarWeb2Component />
          <BannerWebComponent
            show={true}
            title={<Trans i18nKey="sections.section2" />}
            subTitle={<Trans i18nKey="sections.description2" />}
          />
          <WorkoutWebComponent
            progressBar={props.gymWorkout.progressBar}
            addDone={props.addDone}
            isLoading={props.gymWorkout.isLoading}
            errMess={props.gymWorkout.errMess}
            videos={props.gymWorkout.gymWorkout}
            doneLoading={props.gymWorkout.doneLoading}
            handleFavorite={props.handleFavorite}
            favLoading={props.gymWorkout.favLoading}
            initialVideo={
              props.gymWorkout.gymWorkout.length > 0
                ? props.gymWorkout.gymWorkout.filter(
                    (video) => video.type === "workout"
                  )[0]
                : null
            }
          />
        </div>
      </div>
      <div className="d-xl-none">
        <NavbarMob2Component />
        <BannerMobComponent
          show={true}
          title={<Trans i18nKey="sections.section2" />}
          subTitle={<Trans i18nKey="sections.description2" />}
        />
        <WorkoutMobComponent
          progressBar={props.gymWorkout.progressBar}
          addDone={props.addDone}
          isLoading={props.gymWorkout.isLoading}
          errMess={props.gymWorkout.errMess}
          videos={props.gymWorkout.gymWorkout}
          doneLoading={props.gymWorkout.doneLoading}
          handleFavorite={props.handleFavorite}
          favLoading={props.gymWorkout.favLoading}
        />
      </div>
      <FooterComponent />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(GymWorkout);
