import React ,{useState ,useRef,useEffect}from "react";
import { Trans, useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { handleFavorite, addUserRate } from "../redux/ActionCreators";
import Seo from "../components/Seo";
import NavbarWeb2Component from "../components/navbar/NavbarWeb2Component";
import NavbarMob2Component from "../components/navbar/NavbarMob2Component";
// import BannerCompetitionComponent from "../components/banner/BannerCompetitionComponent";
import VideoDetailsComponent from "../components/videos/VideoDetailsComponent";
import CompetitonTitleComponent from "../components/banner/CompetitonTitleComponent";
import VideoCardWebComponent from "../components/videos/VideoCardWebComponent";
import FooterComponent from "../components/footer/FooterComponent";
import { Container } from "react-bootstrap";

const mapStateToProps = (state) => {
  return {
    compHeader: state.competitionHeader,
    nutritionVideos: state.nutritionVideos,
    alyMazharTips: state.alyMazharTips,
    faq: state.faq,
    gymFav: state.gymFav,
    homeFav: state.homeFav,
    // compUsers: state.compUsers,
    // challenge: state.competition,
    uploadedVideos: state.uploadedVideos,
    rateState: state.rateState,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleFavorite: (video) => dispatch(handleFavorite(video)),
  addUserRate: (challengeId, vote) => dispatch(addUserRate(challengeId, vote)),
});

const getDetails = (type, id, props) => {
  switch (type) {
    case "nutrition":
      return {
        video: props.nutritionVideos.nutritionVideos.filter(
          (video) => video.id === parseInt(id, 10)
        )[0],
        related: props.nutritionVideos.nutritionVideos.filter(
          (video) => video.id !== parseInt(id, 10)
        ),
        bannerText: <Trans i18nKey="videoDetails.banner.related" />,
      };

    case "questions":
      return {
        video: props.faq.faq.filter(
          (video) => video.id === parseInt(id, 10)
        )[0],
        related: props.faq.faq.filter((video) => video.id !== parseInt(id, 10)),
        bannerText: <Trans i18nKey="videoDetails.banner.related" />,
      };

    case "challenge":
      return {
        video: props.challenge.competitions.filter(
          (video) => video.id === parseInt(id, 10)
        )[0],
        related: props.challenge.competitions.filter(
          (video) => video.id !== parseInt(id, 10)
        ),
        bannerText: <Trans i18nKey="videoDetails.banner.users" />,
      };
    case "profile":
      return {
        video: props.gymFav.gymFavorites
          .concat(props.homeFav.homeFavorites)
          .filter((video) => video.id === parseInt(id, 10))[0],
        related: props.gymFav.gymFavorites
          .concat(props.homeFav.homeFavorites)
          .filter((video) => video.id !== parseInt(id, 10)),
        bannerText: <Trans i18nKey="videoDetails.banner.related" />,
      };
    case "users":
      return {
        video: props.compUsers.compUsers.filter(
          (video) => video.id === parseInt(id, 10)
        )[0],
        related: props.compUsers.compUsers.filter(
          (video) => video.id !== parseInt(id, 10)
        ),
        bannerText: <Trans i18nKey="videoDetails.banner.users" />,
      };
    case "uploaded":
      return {
        video: props.uploadedVideos.uploadedVideos.filter(
          (video) => video.id === parseInt(id, 10)
        )[0],
        related: props.uploadedVideos.uploadedVideos.filter(
          (video) => video.id !== parseInt(id, 10)
        ),
        bannerText: <Trans i18nKey="videoDetails.banner.users" />,
      };
    case "amt":
      return {
        video: props.alyMazharTips.alyMazharTips.filter(
          (video) => video.id === parseInt(id, 10)
        )[0],
        related: props.alyMazharTips.alyMazharTips.filter(
          (video) => video.id !== parseInt(id, 10)
        ),
        bannerText: <Trans i18nKey="videoDetails.banner.users" />,
      };
    default:
      return "test";
  }
};

const VideoDetails = (props) => {
  const { type, id } = useParams();
  const { t, i18n } = useTranslation();

  const ref = useRef();
  useEffect(() => {
     ref?.current?.scrollIntoView();
  }, [id, type]);
  if (
    props.nutritionVideos.isLoading ||
    props.faq.isLoading ||
    props.gymFav.isLoading ||
    props.homeFav.isLoading ||
    // props.compUsers.isLoading ||
    // props.challenge.isLoading ||
    props.uploadedVideos.isLoading
  ) {
    return <div></div>;
  } else {
    let content = getDetails(type, id, props);

    return (
      <div  ref={ref}>
        <Seo
          title={`${t("title")} | ${
            content.video.titleEn === undefined
              ? content.video.competition.titleEn
              : content.video.titleEn
          }`}
          description={` ${t("title")} | ${
            content.video.titleEn === undefined
              ? i18n === "en"
                ? content.video.competition.descriptionEn
                : content.video.competition.description
              : i18n === "en"
              ? content.video.descriptionEn
              : content.video.description
          }`}
          pathSlug=""
        />

        <div   ref={ref}>
          <div className="d-none d-xl-block">
            <NavbarWeb2Component />
          </div>
          <div className="d-xl-none"  >
            <NavbarMob2Component />
          </div>
          {/* <BannerCompetitionComponent
            isLoading={props.compHeader.isLoading}
            errMess={props.compHeader.errMess}
            compHeader={props.compHeader.competitionHeader}
          /> */}
          <VideoDetailsComponent
            type={type}
            video={content.video}
            addRate={props.addUserRate}
            rateState={props.rateState}
          />
          {content.related.length > 0 ? (
            <div>
              {/* <CompetitonTitleComponent text={content.bannerText} /> */}
              <VideoCardWebComponent
                type={type}
                colXsSize={12}
                colMdSize={6}
                colLgSize={4}
                videos={content.related.slice(0, 3)}
                handleFavorite={props.handleFavorite}
                addRate={props.addUserRate}
                rateState={props.rateState}
              />
            </div>
          ) : null}
       
         
        
     
        </div>

        <FooterComponent />
      </div>
    );
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoDetails);
