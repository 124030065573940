import React from "react";
import { Container } from "react-bootstrap";
import "./TermsAndConditionsComponent.css";
const TermsAndConditionsComponent = () => {
  return (
    <Container dir="rtl" className="termsCond_Container my-3">
      <h4 className="font-weight-bold fs-xm color-primary-light">
        طريقه الأشتراك في التحدى :
      </h4>
      <ul className="fs-md">
        <li className="mb-3">
          بمجرد الإتصال على رقم #5131* علي شبكة شركه اتصالات واشترك العميل سوف
          يستقبل يومياً تمارين من كابتن علي مظهر ويتيح له الدخول في تحديات علي
          مظهر وفرصه للفوز بمعدات أو أدوات رياضيه تسمح بالاستعمال المنزلى إذا
          حصل علي أعلي نسب تصويت في آخر شهر رمضان
        </li>
        <li className="mb-3">
          يحصل المتحدى علي الجائزة أيضا في حاله التساوى مع متحدى اخر في الأعلي
          تصويتا
        </li>
      </ul>
      <h4 className="font-weight-bold fs-xm color-primary-light">
        الشروط والأحكام الخاصة :
      </h4>
      <ol className="fs-md">
        <li className="mb-3">
          يشترط ان يكون العميل الفائز هو الحائز الفعلي والرسمي للخط كما يقر ان
          كافة البيانات المتعلقة بالخط الفائز صحيحة، وفي حالة فوز أي عميل
          بالجائزة وثبوت تقديمه لأي بيانات أو معلومات غير صحيحة، فان اتصالات مصر
          تحتفظ بكامل الحق في استبعاده من قائمة الفائزين وحجب الجائزة عنه ويخضع
          تقدير ما أذا كانت البيانات المقدمة من العميل صحيحه من عدمه لمطلق تقدير
          اتصالات مصر.
        </li>
        <li className="mb-3">
          إذا كان العميل الفائز يعتبر تحت السن القانوني يستوفى وجود ولى الامر
          معه لاستلام الجائزة علي ان يكون معه بطاقة شخصية و شهادة ميلاد للفائز
          لإثبات النسب.
        </li>
        <li className="mb-3">
          يقوم العملاء الفائزين باستلام الجائزة من الاماكن التي سيتم توجيههم
          اليها وقت اعلامهم بالفوز.
        </li>
        <li className="mb-3">
          يجب على المتحدي عند استلامه للجائزة أن يقوم بتقديم أصل بطاقة الرقم
          القومي الخاص به للاطلاع عليه من قبل الشركة مع حقها في الاحتفاظ بصورة
          من بطاقة الرقم القومي، وإلا فلن يتمكن من الحصول على الجائزة، كما أنه
          يتعين على العميل الفائز أن يقوم بالتوقيع على اقرار باستلامه الجائزة.
        </li>
        <li className="mb-3">يجب على الفائز ان يحمل خط اتصالات.</li>
        <li className="mb-3">
          في حالة كون رقم الخط الفائز مملوك لأحدي الشركات، وكان أحد موظفيها هو
          المستخدم، يتم التأكد من هوية المستخدم وأهليته للفوز من عدمه من المفوض
          الرسمي للشركة، وفي حالة اعتراض المفوض الرسمي (بشرط أن يكون هذا
          الاعتراض مكتوب ومبرر ويتم تسليمه لشركة اتصالات مصر خلال 48 ساعة من
          تاريخ الاعلام بالجائزة) على تسليم الجائزة لمستخدم الخط يتم استبعاد
          الفائز من قائمة الفائزين ويتم اختيار متسابق اخر للفوز بالجائزة.
        </li>
        <li className="mb-3">
          في حالة ارسال رسالة نصية للفائز وعدم الرد من جهته برقم هاتفه في موعد
          أقصاه 3 ساعات سيتم اختيار فائز آخر.
        </li>
        <li className="mb-3">
          في حالة الاتصال بالفائز وعدم الرد بعد ثلاث محاولات سيتم اختيار فائز
          آخر.
        </li>
        <li className="mb-3">
          لا يحق لأي من موظفي اتصالات مصر وأقاربهم حتى الدرجة الرابعة أن يشتركوا
          (بشكل مباشر أو غير مباشر) في التحدي أو أن يفوز أي منهم بأي من الجوائز.
        </li>
        <li className="mb-3">
          يقوم العميل الفائز باستلام الجائزة ويتم تسليمه خلال أسبوع من وقت إعلان
          فوزهم، وفي حالة عدم الالتزام بالمدة المحددة سيتم فوراً اختيار فائز
          آخر.
        </li>
        <li className="mb-3">
          تقوم اتصالات مصر بالإعلان عن الفائزين من خلال مختلف وسائل الاعلام و
          يحق لها ان تقوم بوضع صور أو فيديو أو أسم الفائز في الاعلانات الدعائية
          الخاصة بالشركة و التي تعرض في جميع الوسائل الإعلامية و مواقع التواصل
          الاجتماعي و لا يجوز للفائز الرجوع بأي حقوق أو مطالبات مالية أو دعاوي
          تتعلق بنشر صور أو فيديو أو أسم العميل الفائز، و يحق لاتصالات مصر ان
          تطلب من الفائزين الظهور في وسائل الإعلام المختلفة التي تحددها الشركة
          للإعلان عن التحدي، ويعتبر قبول العميل لهذا الشرط شرطا أساسياً
          لاستحقاقه للجائزة.
        </li>
      </ol>
      <h4 className="font-weight-bold fs-xm color-primary-light">
        يقر العميل / الفائز بأن :
      </h4>
      <ol className="fs-md">
        <li className="mb-3">
          لا يجوز تقديم أي ادعاء بحق اتصالات مصر والشركات التابعة لها والجهات
          الراعية والجهات المرتبطة فيما يتعلق بأي من الخسائر أو الإصابات أو
          الأضرار النفسية التي قد تنتج عن عدم استحقاقه أو فوزه بالجائزة، أو
          الحقوق، أو المطالب، أو الأفعال من أي طبيعة كانت والناتجة عن المشاركة
          في التحدي.
        </li>
        <li className="mb-3">
          لا يجوز المشاركة في أي برامج تلفزيونية تخص التحدي، بصفته فائز أو مشارك
          بالتحدي بدون موافقة ممثل شركة اتصالات مصر، على ان يتحمل العميل
          المسئولية القانونية في حالة المخالفة.
        </li>
        <li className="mb-3">
          لا يجوز تغيير أي من تدابير أو مواعيد استلام الجائزة ولا يجوز التنازل
          عن أو تبديل أو بيع الجائزة أو تغير اسم الفائز.
        </li>
        <li className="mb-3">
          اتصالات مصر غير مسؤولة عن أي ضرر أو خسارة أو إصابة يتعرض لها أي عميل
          يدخل التحدي أو تنتج عن قبول الجائزة والمشار اليها باعلاه.{" "}
        </li>
        <li className="mb-3">
          يحق لاتصالات مصر استخدام الفيديوهات والصور المسجلة في الحملات الدعائية
          والترويجية والاعلانات الخاصة بالتحدي.
        </li>
        <li className="mb-3">
          اتصالات غير مسؤولة عن نقل الجائزة من مكان لأخر ويجب علي الفائز أن
          يستلم الجائزة من المكان التي تم تحديده.{" "}
        </li>
        <li className="mb-3">
          اتصالات مصر غير مسؤولة عن أي ضرر أو عيب صناعي أو تصليح أو سرقة
          للجائزة.
        </li>
        <li className="mb-3">
          أن يكون العميل كامل الأهلية ويستوفى جميع الشروط والاحكام المذكورة
          أعلاه.
        </li>
        <li className="mb-3">
          لا يحق للعميل بعد استلام الجائزة أن يقوم بتقديم أي نوع من الشكاوى
          لاتصالات أو أي جهة أخرى، أو يدّعي عدم استلام الجائزة. على العميل ان
          يتحمل المسئولية القانونية في حالة المخالفة.
        </li>
        <li className="mb-3">
          اتصالات لديها كامل الحق في تعديل شروط وأحكام التحدي في أي وقت.
        </li>
      </ol>
      <p className="fs-sm color-primary-light">
        تخضع هذه الشروط والأحكام وتفسر وفقاً لقوانين جمهورية مصر العربية.
      </p>
    </Container>
  );
};

export default TermsAndConditionsComponent;
