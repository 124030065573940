import * as ActionTypes from "./ActionTypes";

export const CompetitionHeader = (
  state = { isLoading: true, errMess: null, competitionHeader: [] },
  action
) => {
  switch (action.type) {
    case ActionTypes.ADD_COMPETITION_HEADER:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        competitionHeader: action.payload,
      };

    case ActionTypes.COMPETITION_HEADER_LOADING:
      return {
        ...state,
        isLoading: true,
        errMess: null,
        competitionHeader: [],
      };

    case ActionTypes.COMPETITION_HEADER_FAILED:
      return { ...state, isLoading: false, errMess: action.payload };

    default:
      return state;
  }
};

export const Competition = (
  state = { isLoading: true, errMess: null, competitions: [] },
  action
) => {
  switch (action.type) {
    case ActionTypes.ADD_COMPETITION:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        competitions: action.payload,
      };

    case ActionTypes.COMPETITION_LOADING:
      return { ...state, isLoading: true, errMess: null, competitions: [] };

    case ActionTypes.COMPETITION_FAILED:
      return { ...state, isLoading: false, errMess: action.payload };

    default:
      return state;
  }
};

export const CompetitionRanking = (
  state = { isLoading: true, errMess: null, compRanking: [] },
  action
) => {
  switch (action.type) {
    case ActionTypes.ADD_COMPRANKING:
      action.payload.sort(function (a, b) {
        return b.totalVotes - a.totalVotes;
      });
      return {
        ...state,
        isLoading: false,
        errMess: null,
        compRanking: action.payload,
      };

    case ActionTypes.COMPRANKING_LOADING:
      return { ...state, isLoading: true, errMess: null, compRanking: [] };

    case ActionTypes.COMPRANKING_FAILED:
      return { ...state, isLoading: false, errMess: action.payload };

    default:
      return state;
  }
};

export const CompetitionUsers = (
  state = { isLoading: true, errMess: null, compUsers: [] },
  action
) => {
  switch (action.type) {
    case ActionTypes.ADD_COMPETITIONUSERS:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        compUsers: action.payload,
      };

    case ActionTypes.COMPETITIONUSERS_LOADING:
      return { ...state, isLoading: true, errMess: null, compUsers: [] };

    case ActionTypes.COMPETITIONUSERS_FAILED:
      return { ...state, isLoading: false, errMess: action.payload };

    default:
      return state;
  }
};

export const CompetitionUploadedVideos = (
  state = { isLoading: true, errMess: null, uploadedVideos: [] },
  action
) => {
  switch (action.type) {
    case ActionTypes.ADD_UPLOADED_VIDEO:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        uploadedVideos: action.payload,
      };

    case ActionTypes.UPLOADED_VIDEO_LOADING:
      return { ...state, isLoading: true, errMess: null, uploadedVideos: [] };

    case ActionTypes.UPLOADED_VIDEO_FAILED:
      return { ...state, isLoading: false, errMess: action.payload };

    default:
      return state;
  }
};
