import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Seo from "../components/Seo";
import { addUserRate } from "../redux/ActionCreators";
import NavbarWeb2Component from "../components/navbar/NavbarWeb2Component";
import NavbarMob2Component from "../components/navbar/NavbarMob2Component";
import BannerCompetitionComponent from "../components/banner/BannerCompetitionComponent";
import CompetitonTitleComponent from "../components/banner/CompetitonTitleComponent";
import VideoCardWebComponent from "../components/videos/VideoCardWebComponent";
import FooterComponent from "../components/footer/FooterComponent";

const mapStateToProps = (state) => {
  return {
    compHeader: state.competitionHeader,
    compUsers: state.compUsers,
    rateState: state.rateState,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addUserRate: (challengeId, vote) => dispatch(addUserRate(challengeId, vote)),
});

const UsersVideos = (props) => {
  const { t } = useTranslation();
  return (
    <div>
      <Seo
        title={` ${t("title")} | ${t("btn.browse")}`}
        description={t("competition.banner.subTitle")}
        pathSlug="/users/challenges"
      />

      <div className="d-none d-xl-block">
        <NavbarWeb2Component />
      </div>
      <div className="d-xl-none">
        <NavbarMob2Component />
      </div>
      <BannerCompetitionComponent
        isLoading={props.compHeader.isLoading}
        errMess={props.compHeader.errMess}
        compHeader={props.compHeader.competitionHeader}
      />
      <CompetitonTitleComponent text="videoDetails.banner.users" />
      <VideoCardWebComponent
        type="users"
        colXsSize={12}
        colMdSize={6}
        colLgSize={4}
        isLoading={props.compUsers.isLoading}
        errMess={props.compUsers.errMess}
        videos={props.compUsers.compUsers.slice(0, 3)}
        addRate={props.addUserRate}
        rateState={props.rateState}
      />
      <VideoCardWebComponent
        type="users"
        colXsSize={12}
        colMdSize={6}
        colLgSize={3}
        isLoading={props.compUsers.isLoading}
        errMess={props.compUsers.errMess}
        videos={props.compUsers.compUsers.slice(3)}
        addRate={props.addUserRate}
        rateState={props.rateState}
      />
      <FooterComponent />
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(UsersVideos);
