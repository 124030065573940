import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Button, Container, ButtonGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Seo from "../components/Seo";
import NavbarWeb2Component from "../components/navbar/NavbarWeb2Component";
import NavbarMob2Component from "../components/navbar/NavbarMob2Component";
import BannerWebComponent from "../components/banner/BannerWebComponent";
import BannerMobComponent from "../components/banner/BannerMobComponent";
import VideoCardWebComponent from "../components/videos/VideoCardWebComponent";
import FooterComponent from "../components/footer/FooterComponent";

const mapStateToProps = (state) => {
  return { videos: state.nutritionVideos };
};

const NutritionEpisodes = (props) => {
  const { t } = useTranslation();
  return (
    <div>
      <Seo
        title={` ${t("title")} | ${t("sections.section5")} `}
        description={t("sections.description5")}
        pathSlug="nutrition-episodes"
      />

      <div className="d-none d-xl-block">
        <NavbarWeb2Component />
        <BannerWebComponent
          title={<Trans i18nKey="sections.section5" />}
          subTitle={<Trans i18nKey="sections.description5" />}
        />
      </div>
      <div className="d-xl-none">
        <NavbarMob2Component />
        <Container>
          <ButtonGroup className="w-100 nutrition-btn-grp">
            <Button
              className="btn w-50 mx-auto my-3 fs-xs secondary-btn"
              as={Link}
              to="/nutrition-tips"
            >
              <Trans i18nKey="navbar.Tips" />
            </Button>
            <Button
              className="btn w-50 mx-auto my-3 fs-xs active"
              as={Link}
              to="/nutrition-episodes"
            >
              <Trans i18nKey="navbar.Eposides" />
            </Button>
          </ButtonGroup>
        </Container>
        <BannerMobComponent
          title={<Trans i18nKey="navbar.Eposides" />}
          subTitle={<Trans i18nKey="sections.description5" />}
        />
      </div>
      <VideoCardWebComponent
        type="nutrition"
        colXsSize={12}
        colMdSize={6}
        colLgSize={4}
        isLoading={props.videos.isLoading}
        errMess={props.videos.errMess}
        videos={props.videos.nutritionVideos}
      />

      <FooterComponent />
    </div>
  );
};
export default connect(mapStateToProps)(NutritionEpisodes);
