import React, { useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import VideoPlayerComponent from "../videos/VideoPlayerComponent";
import PerfectScrollbar from "react-perfect-scrollbar";
import DoneIconComponent from "../icons/DoneIconComponent";
import FavIconComponent from "../icons/FavIconComponent";
import { useTranslation } from "react-i18next";
import { GiStopwatch } from "react-icons/gi";
import WorkoutWebLoader from "./WorkoutWebLoader";
import { ProgressBar } from "react-bootstrap";
import "./WorkoutWebComponent.css";

const WorkoutWebComponent = (props) => {
  const { i18n } = useTranslation();
  const [videoSrc, setVideoSrc] = useState(
    props.initialVideo === null ? null : props.initialVideo.video
  );

  let handleVideo = (video) => {
    setVideoSrc(video);
  };

  if (props.isLoading || props.errMess) {
    return <WorkoutWebLoader />;
  } else {
    return (
      <Container fluid className="workOutWeb p-0">
        <ProgressBar now={props.progressBar} label={`${props.progressBar}%`} />
        <Row noGutters>
          <Col xs={7}>
            <VideoPlayerComponent videoSrc={videoSrc} />
          </Col>
          <Col className="playlist-container">
            <PerfectScrollbar>
              <ul className="list-group">
                {props.videos.map((video) =>
                  video.type === "workout" ? (
                    <li className="list-group-item mb-1 d-flex" key={video.id}>
                      <Col xs={10} onClick={() => handleVideo(video.video)}>
                        <h6 className="fs-sm color-tertiary-light">
                          {i18n.language === "en" ? video.titleEn : video.title}
                        </h6>
                        <p className="fs-md color-tertiary">
                          {i18n.language === "en"
                            ? video.descriptionEn
                            : video.description}
                        </p>
                      </Col>
                      <Col className="d-flex m-auto p-0 iconContainer">
                        <div onClick={() => props.handleFavorite(video)}>
                          <FavIconComponent
                            isFav={video.favorite}
                            favLoading={props.favLoading}
                          />
                        </div>
                        <div onClick={() => props.addDone(video)}>
                          <DoneIconComponent
                            isDone={video.done}
                            doneLoading={props.doneLoading}
                          />
                        </div>
                      </Col>
                    </li>
                  ) : (
                    <li
                      className="list-group-item mb-1 d-flex break-li"
                      key={video.id}
                    >
                      <h6 className="fs-md m-2 text-center color-primary-light">
                        <GiStopwatch className="fs-xm mx-1 mb-1" />
                        {i18n.language === "en" ? video.titleEn : video.title}
                      </h6>
                    </li>
                  )
                )}
              </ul>
            </PerfectScrollbar>
          </Col>
        </Row>
      </Container>
    );
  }
};

export default WorkoutWebComponent;
