import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Seo from "../components/Seo";
import NavbarWeb2Component from "../components/navbar/NavbarWeb2Component";
import NavbarMob2Component from "../components/navbar/NavbarMob2Component";
import BannerCompetitionComponent from "../components/banner/BannerCompetitionComponent";
import CompetitonTitleComponent from "../components/banner/CompetitonTitleComponent";
import RankingComponent from "../components/competition/RankingComponent";
import FooterComponent from "../components/footer/FooterComponent";

const mapStateToProps = (state) => {
  return {
    compHeader: state.competitionHeader,
    compRanking: state.compRanking,
  };
};

const CompetitionRanking = (props) => {
  const { t } = useTranslation();
  return (
    <div>
      <Seo
        title={` ${t("title")} | ${t("navbar.competition ranking")}`}
        description={t("competition.banner.subTitle")}
        pathSlug="ranking"
      />

      <div className="d-none d-xl-block">
        <NavbarWeb2Component />
      </div>
      <div className="d-xl-none">
        <NavbarMob2Component />
      </div>
      <BannerCompetitionComponent
        isLoading={props.compHeader.isLoading}
        errMess={props.compHeader.errMess}
        compHeader={props.compHeader.competitionHeader}
      />
      <CompetitonTitleComponent text="competition.banner.top10" />
      <RankingComponent
        isLoading={props.compRanking.isLoading}
        errMess={props.compRanking.errMess}
        ranking={props.compRanking.compRanking}
      />
      <FooterComponent />
    </div>
  );
};

export default connect(mapStateToProps)(CompetitionRanking);
