import * as ActionTypes from "./ActionTypes";

export const getUserProfile = (
  state = { isLoading: true, errMess: null, userProfile: [] },
  action
) => {
  switch (action.type) {
    case ActionTypes.ADD_UPDATE_PROFILE:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        userProfile: action.payload,
      };

    case ActionTypes.UPDATE_PROFILE_LOADING:
      return { ...state, isLoading: true, errMess: null, userProfile: [] };

    case ActionTypes.UPDATE_PROFILE_FAILED:
      return { ...state, isLoading: false, errMess: action.payload };

    default:
      return state;
  }
};
