import "./assets/css/fonts.css";
import "./assets/css/colors.css";
import "./assets/css/general.css";
import "./assets/css/media-quiries.css";
import React, { Suspense } from "react";
import MainComponent from "./MainComponent";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ConfigureStore } from "./redux/configureStore";

const store = ConfigureStore();
function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter>
          <Suspense fallback="">
            <MainComponent />
          </Suspense>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
