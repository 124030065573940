import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { handleFavorite, fetchUserProfile } from "../redux/ActionCreators";
import Seo from "../components/Seo";
import NavbarWeb2Component from "../components/navbar/NavbarWeb2Component";
import UserDataComponent from "../components/user/profile/UserDataComponent";
import NavbarMob2Component from "../components/navbar/NavbarMob2Component";
import VideoCardWebComponent from "../components/videos/VideoCardWebComponent";
import FooterComponent from "../components/footer/FooterComponent";

const mapStateToProps = (state) => {
  return {
    userProfile: state.userProfile,
    gymFav: state.gymFav,
    homeFav: state.homeFav,
    uploadedVideos: state.uploadedVideos,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleFavorite: (video) => dispatch(handleFavorite(video)),
  fetchUserProfile: (image, userName, email, password) =>
    dispatch(fetchUserProfile(image, userName, email, password)),
});

const Profile = (props) => {
  const { t } = useTranslation();
  return (
    <div>
      <Seo
        title={` ${t("title")} | Profile`}
        description=""
        pathSlug="profile"
      />

      <div className="d-none d-xl-block">
        <NavbarWeb2Component />
      </div>
      <div className="d-xl-none">
        <NavbarMob2Component />
      </div>
      <UserDataComponent
        isLoading={props.userProfile.isLoading}
        errMess={props.userProfile.errMess}
        userData={props.userProfile.userProfile}
        updateData={props.fetchUserProfile}
        gymFav={
          <VideoCardWebComponent
            colXsSize={12}
            colMdSize={6}
            colLgSize={4}
            isLoading={props.gymFav.isLoading}
            errMess={props.gymFav.errMess}
            videos={props.gymFav.gymFavorites}
            type="profile"
            handleFavorite={props.handleFavorite}
            emptyMess={<Trans i18nKey="profile.no-gym-video" />}
          />
        }
        homeFav={
          <VideoCardWebComponent
            colXsSize={12}
            colMdSize={6}
            colLgSize={4}
            isLoading={props.homeFav.isLoading}
            errMess={props.homeFav.errMess}
            videos={props.homeFav.homeFavorites}
            type="profile"
            handleFavorite={props.handleFavorite}
            emptyMess={<Trans i18nKey="profile.no-home-video" />}
          />
        }
        uploaded={
          <VideoCardWebComponent
            colXsSize={12}
            colMdSize={6}
            colLgSize={4}
            isLoading={props.uploadedVideos.isLoading}
            errMess={props.uploadedVideos.errMess}
            videos={props.uploadedVideos.uploadedVideos}
            type="uploaded"
            emptyMess={<Trans i18nKey="profile.no-gym-video" />}
          />
        }
      />
      <FooterComponent />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
