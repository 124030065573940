import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { Trans, useTranslation } from "react-i18next";

const HomeMobComponent = () => {
  const { i18n } = useTranslation();

  return (
    <Container className="homeMobiContainer" fluid>
      <div className="mt-4">
        <p className="text-center">
          <Trans i18nKey="welcome" />
        </p>
        <Link to={"/gym-workout"}>
          <Card className="my-3">
            <Card.Body>
              <Card.Title className="fs-xm color-primary-light">
                <Row>
                  <Col xs={10}>
                    <Trans i18nKey="sections.section2" />
                  </Col>
                  <Col className="text-center  fs-xxm color-primary-light">
                    {i18n.language === "en" ? (
                      <IoIosArrowForward />
                    ) : (
                      <IoIosArrowBack />
                    )}
                  </Col>
                </Row>
              </Card.Title>
              <Card.Text className="fs-xs">
                <Trans i18nKey="sections.description2" />
              </Card.Text>
            </Card.Body>
          </Card>
        </Link>
        <Link to={"/home-workout"}>
          <Card className="my-3">
            <Card.Body>
              <Card.Title className="fs-xm color-primary-light">
                <Row>
                  <Col xs={10}>
                    <Trans i18nKey="sections.section1" />
                  </Col>
                  <Col className="text-center  fs-xxm color-primary-light">
                    {i18n.language === "en" ? (
                      <IoIosArrowForward />
                    ) : (
                      <IoIosArrowBack />
                    )}
                  </Col>
                </Row>
              </Card.Title>
              <Card.Text className="fs-xs">
                <Trans i18nKey="sections.description1" />
              </Card.Text>
            </Card.Body>
          </Card>
        </Link>
        <Link to={"/nutrition-tips"}>
          <Card className="my-3">
            <Card.Body>
              <Card.Title className="fs-xm color-primary-light">
                <Row>
                  <Col xs={10}>
                    <Trans i18nKey="sections.section5" />
                  </Col>
                  <Col className="text-center  fs-xxm color-primary-light">
                    {i18n.language === "en" ? (
                      <IoIosArrowForward />
                    ) : (
                      <IoIosArrowBack />
                    )}
                  </Col>
                </Row>
              </Card.Title>
              <Card.Text className="fs-xs">
                <Trans i18nKey="sections.description5" />
              </Card.Text>
            </Card.Body>
          </Card>
        </Link>
        <Link to={"/aly-mazhar-tips"}>
          <Card className="my-3">
            <Card.Body>
              <Card.Title className="fs-xm color-primary-light">
                <Row>
                  <Col xs={10}>
                    <Trans i18nKey="sections.section7" />
                  </Col>
                  <Col className="text-center  fs-xxm color-primary-light">
                    {i18n.language === "en" ? (
                      <IoIosArrowForward />
                    ) : (
                      <IoIosArrowBack />
                    )}
                  </Col>
                </Row>
              </Card.Title>
              <Card.Text className="fs-xs">
                <Trans i18nKey="sections.description7" />
              </Card.Text>
            </Card.Body>
          </Card>
        </Link>
        <Link to={"/ask"}>
          <Card className="my-3">
            <Card.Body>
              <Card.Title className="fs-xm color-primary-light">
                <Row>
                  <Col xs={10}>
                    <Trans i18nKey="sections.section3" />
                  </Col>
                  <Col className="text-center  fs-xxm color-primary-light">
                    {i18n.language === "en" ? (
                      <IoIosArrowForward />
                    ) : (
                      <IoIosArrowBack />
                    )}
                  </Col>
                </Row>
              </Card.Title>
              <Card.Text className="fs-xs">
                <Trans i18nKey="sections.description3" />
              </Card.Text>
            </Card.Body>
          </Card>
        </Link>
        <Link to={"/questions"}>
          <Card className="my-3">
            <Card.Body>
              <Card.Title className="fs-xm color-primary-light">
                <Row>
                  <Col xs={10}>
                    <Trans i18nKey="sections.section4" />
                  </Col>
                  <Col className="text-center  fs-xxm color-primary-light">
                    {i18n.language === "en" ? (
                      <IoIosArrowForward />
                    ) : (
                      <IoIosArrowBack />
                    )}
                  </Col>
                </Row>
              </Card.Title>
              <Card.Text className="fs-xs">
                <Trans i18nKey="sections.description4" />
              </Card.Text>
            </Card.Body>
          </Card>
        </Link>
      </div>
    </Container>
  );
};

export default HomeMobComponent;
