import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useParams } from "react-router";
import Seo from "../components/Seo";
import NavbarWeb2Component from "../components/navbar/NavbarWeb2Component";
import NavbarMob2Component from "../components/navbar/NavbarMob2Component";
// import BannerCompetitionComponent from "../components/banner/BannerCompetitionComponent";
import UploadComponent from "../components/user/profile/UploadComponent";
import FooterComponent from "../components/footer/FooterComponent";

const mapStateToProps = (state) => {
  return {
    compHeader: state.competitionHeader,
  };
};

const Upload = (props) => {
  const { id, trials } = useParams();
  const { t } = useTranslation();
  return (
    <div>
      <Seo
        title={` ${t("title")} | ${t("btn.upload")}`}
        description={t("competition.banner.subTitle")}
        pathSlug="upload"
      />

      <div className="d-none d-xl-block">
        <NavbarWeb2Component />
      </div>
      <div className="d-xl-none">
        <NavbarMob2Component />
      </div>
      {/* <BannerCompetitionComponent
        isLoading={props.compHeader.isLoading}
        errMess={props.compHeader.errMess}
        compHeader={props.compHeader.competitionHeader}
      /> */}
      <div className="my-4">
        <UploadComponent
          challengeId={id}
          trials={trials}
          type="video"
          accept="video/mp4,video/x-m4v,video/*"
          title="upload.title_video"
          // description="upload.description_video"
        />
      </div>
      <FooterComponent />
    </div>
  );
};

export default connect(mapStateToProps)(Upload);
