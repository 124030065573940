import React from "react";
import {
  Row,
  Col,
  Container,
  Image,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import BannerCompetitionComponentLoader from "./BannerCompetitionComponentLoader";
import { countDown } from "../../globalFn";
import "./BannerCompetitionComponent.css";

const BannerCompetitionComponent = (props) => {
  const { i18n } = useTranslation();
  if (props.isLoading || props.errMess) {
    return <BannerCompetitionComponentLoader />;
  } else {
    return (
      <Container fluid className="bg-secondary-dark">
        <Container className="py-4 p-0">
          <Row>
            <Col xs={12} md={9} xl={10}>
              <Row>
                <Col xs={12} md={2} xl={1} className="p-2 m-auto">
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id="button-tooltip" className="gift-tooltip">
                        <Image
                          src={
                            props.compHeader[0].prize.prizeImage
                              ? process.env.REACT_APP_MEDIA_BASE_URL +
                                props.compHeader[0].prize.prizeImage
                              : "/img/gift.png"
                          }
                        />
                      </Tooltip>
                    }
                  >
                    <div className="eclipse mx-2">
                      <Image src="/img/icons/gift.svg" className="gift-img" />
                    </div>
                  </OverlayTrigger>
                </Col>
                <Col>
                  <div className="my-auto mx-2">
                    <p className="color-primary-light mr-auto my-2 fs-xm">
                      {i18n.language === "en"
                        ? props.compHeader[0].titleEn
                        : props.compHeader[0].titleAr}
                    </p>
                    <p className=" mr-auto my-auto fs-md color-tertiary">
                      {i18n.language === "en"
                        ? props.compHeader[0].descriptionEn
                        : props.compHeader[0].descriptionAr}
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col
              xs={12}
              md={3}
              xl={2}
              className="m-auto calendar-date-container"
            >
              <div className="m-auto d-flex">
                <div className="mx-1 calendar-date-cont">
                  <p className="calendar-date">
                    {countDown(props.compHeader[0].datetime, 0)}
                  </p>
                  <div className="calendat-box-1"></div>
                  <div className="calendat-box-2"></div>
                </div>
                <div className="mx-1 calendar-date-cont">
                  <p className="calendar-date">
                    {countDown(props.compHeader[0].datetime, 1)}
                  </p>
                  <div className="calendat-box-1"></div>
                  <div className="calendat-box-2"></div>
                </div>
                <p className="mx-1 my-auto fs-xm text-uppercase text-white">
                  <Trans i18nKey="competition.competitionCountDown.days" />
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    );
  }
};

export default BannerCompetitionComponent;
