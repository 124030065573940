import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import UserImgAndRatingComponent from "../user/UserImgAndRatingComponent";
import RankingComponentLoader from "./RankingComponentLoader";
import "./RankingComponent.css";

const RankingComponent = (props) => {
  if (props.isLoading || props.errMess) {
    return <RankingComponentLoader />;
  } else {
    return (
      <Container className="rankingContainer" fluid="lg">
        {props.ranking.map((rank, index) => (
          <Col xs={12} className="bg-secondary-dark p-3 my-3" key={index}>
            <Row>
              <Col xs={9} md={10} className="m-auto">
                <UserImgAndRatingComponent
                  userId={rank.id}
                  userImg={rank.image}
                  userName={rank.name}
                  userRate={rank.totalVotes}
                />
              </Col>
              <Col xs={3} md={2} className="ml-auto my-auto text-center">
                <span className="mx-1 color-primary-light fs-xxl font-weight-bold">
                  {index + 1}
                </span>
              </Col>
            </Row>
          </Col>
        ))}
      </Container>
    );
  }
};

export default RankingComponent;
