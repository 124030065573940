import React, { Component } from "react";
import { Container, Col, Form, Button } from "react-bootstrap";
import { Trans, withTranslation } from "react-i18next";
import ModalComponent from "../../modals/ModalComponent";
import { FiEdit } from "react-icons/fi";
import "./EditProfileComponent.css";

class EditProfileComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: "ask.note5",
      userName: "",
    };
    this.handleUserNameChange = this.handleUserNameChange.bind(this);

    this.submitQuestion = this.submitQuestion.bind(this);
  }

  handleUserNameChange(event) {
    this.setState({ userName: event.target.value });
  }

  submitQuestion = (event) => {
    event.preventDefault();
    this.props.updateData(null, this.state.userName, null, null);
  };
  render() {
    const { t } = this.props;
    return (
      <Container>
        <ModalComponent
          content={
            <Container
              style={{ background: "#121212" }}
              className="p-0 editContainer"
            >
              <Col xs={12} md={7} className="py-5 mx-auto">
                <h6 className="text-center mb-4 fs-sm">
                  <Trans i18nKey="profile.edit" />
                  <FiEdit className="mx-2" />
                </h6>
                <Form onSubmit={this.submitQuestion}>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Control
                      className="fs-xm"
                      type="text"
                      placeholder={t("userForm.formUser")}
                      value={this.state.userName}
                      onChange={this.handleUserNameChange}
                    />
                  </Form.Group>
                  <Button
                    type="submit"
                    value="Submit"
                    variant="primary primary-btn fs-md w-100 py-2"
                    type="submit"
                  >
                    <Trans i18nKey="btn.save" />
                  </Button>
                </Form>
              </Col>
            </Container>
          }
          open={this.props.open}
          toggleModal={this.props.toggleModal}
        />
      </Container>
    );
  }
}

export default withTranslation()(EditProfileComponent);
