import React from "react";
import Modal from "react-responsive-modal";
import "./ModalComponent.css";

const ModalComponent = (props) => {
  return (
    <Modal
      classNames="modalContainer"
      open={props.open}
      onClose={props.toggleModal}
      styles={{
        modal: {
          maxWidth: "100%",
          margin: "auto",
        },
        overlay: {
          background: "rgba(38, 38, 38,0.6)",
          backdropFilter: "blur(9px)",
        },
        closeButton: {
          background: "000000",
          border: "unset",
        },
      }}
      center
    >
      {props.content}
    </Modal>
  );
};

export default ModalComponent;
