import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Button, Container, ButtonGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { fetchNutrition } from "../redux/ActionCreators";
import Seo from "../components/Seo";
import NavbarWeb2Component from "../components/navbar/NavbarWeb2Component";
import NavbarMob2Component from "../components/navbar/NavbarMob2Component";
import BannerWebComponent from "../components/banner/BannerWebComponent";
import BannerMobComponent from "../components/banner/BannerMobComponent";
import ArticleCardComponent from "../components/article/ArticleCardComponent";
import FooterComponent from "../components/footer/FooterComponent";

const mapStateToProps = (state) => {
  return { tips: state.nutritionTips };
};
const mapDispatchToProps = (dispatch) => ({
  loadmore: (type, page, isLoadMore) =>
    dispatch(fetchNutrition(type, page, isLoadMore)),
});

const NutritionTips = (props) => {
  const { t } = useTranslation();
  return (
    <div>
      <Seo
        title={` ${t("title")} | ${t("sections.section6")} `}
        description={t("sections.description6")}
        pathSlug="nutrition-tips"
      />

      <div className="d-none d-xl-block">
        <NavbarWeb2Component />
        <BannerWebComponent
          title={<Trans i18nKey="sections.section6" />}
          subTitle={<Trans i18nKey="sections.description6" />}
        />
      </div>
      <div className="d-xl-none">
        <NavbarMob2Component />
        <Container>
          <ButtonGroup className="w-100 nutrition-btn-grp">
            <Button
              className="btn w-50 mx-auto my-3 fs-xs active"
              as={Link}
              to="/nutrition-tips"
            >
              <Trans i18nKey="navbar.Tips" />
            </Button>
            <Button
              className="btn w-50 mx-auto my-3 fs-xs secondary-btn"
              as={Link}
              to="/nutrition-episodes"
            >
              <Trans i18nKey="navbar.Eposides" />
            </Button>
          </ButtonGroup>
        </Container>
        <BannerMobComponent
          title={<Trans i18nKey="navbar.Tips" />}
          subTitle={<Trans i18nKey="sections.description6" />}
        />
      </div>
      <ArticleCardComponent
        isLoading={props.tips.isLoading}
        errMess={props.tips.errMess}
        articles={props.tips.nutritionTips}
        pageNo={props.tips.pageNo}
        loadMorePerPage={props.loadmore}
      />

      <FooterComponent />
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(NutritionTips);
