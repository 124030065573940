import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Container, Row, Col, Card } from "react-bootstrap";

export default function HomeWebComponentLoader() {
  return (
    <Container className="homeWebContainer">
      <SkeletonTheme color="#202020" highlightColor="#444">
        <div className="mt-4">
          <Col xs={6} className="m-auto">
            <p className="text-center">
              <Skeleton count={1} width={400} />
              <Skeleton count={1} />
            </p>
          </Col>
          <Col xs={12} className="py-5">
            <Row className="justify-content-center m-auto row-flex">
              <Col xs={4} className="my-5">
                <Card className="m-auto h-100">
                  <div className="circle-icon"></div>
                  <Card.Body className="mt-5">
                    <Card.Title className="text-center">
                      <Skeleton count={1} />
                    </Card.Title>
                    <Card.Text className="text-center my-2 mx-5 fs-16">
                      <Skeleton count={3} />
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={4} className="my-5">
                <Card className="m-auto h-100">
                  <div className="circle-icon"></div>
                  <Card.Body className="mt-5">
                    <Card.Title className="text-center">
                      <Skeleton count={1} />
                    </Card.Title>
                    <Card.Text className="text-center my-2 mx-5 fs-16">
                      <Skeleton count={3} />
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={4} className="my-5">
                <Card className="m-auto h-100">
                  <div className="circle-icon"></div>
                  <Card.Body className="mt-5">
                    <Card.Title className="text-center">
                      <Skeleton count={1} />
                    </Card.Title>
                    <Card.Text className="text-center my-2 mx-5 fs-16">
                      <Skeleton count={3} />
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={4} className="my-5">
                <Card className="m-auto h-100">
                  <div className="circle-icon"></div>
                  <Card.Body className="mt-5">
                    <Card.Title className="text-center">
                      <Skeleton count={1} />
                    </Card.Title>
                    <Card.Text className="text-center my-2 mx-5 fs-16">
                      <Skeleton count={3} />
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={4} className="my-5">
                <Card className="m-auto h-100">
                  <div className="circle-icon"></div>
                  <Card.Body className="mt-5">
                    <Card.Title className="text-center">
                      <Skeleton count={1} />
                    </Card.Title>
                    <Card.Text className="text-center my-2 mx-5 fs-16">
                      <Skeleton count={3} />
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </div>
      </SkeletonTheme>
    </Container>
  );
}
