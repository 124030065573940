import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import Seo from "../components/Seo";
import HomeWebComponentLoader from "../components/home/HomeWebComponentLoader";
import HomeMobComponentLoader from "../components/home/HomeMobComponentLoader";
import NavbarWeb1Component from "../components/navbar/NavbarWeb1Component";
import FooterComponent from "../components/footer/FooterComponent";

const HomeLoader = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Seo title={` ${t("title")}`} description={t("intro")} pathSlug="" />
      <NavbarWeb1Component />
      <div className="d-none d-xl-block">
        <Container className="intro-section" fluid>
          <HomeWebComponentLoader />
        </Container>
      </div>
      <div className="d-xl-none">
        <HomeMobComponentLoader />
      </div>
      <FooterComponent />
    </div>
  );
};

export default HomeLoader;
