import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Seo from "../components/Seo";
import NavbarWeb2Component from "../components/navbar/NavbarWeb2Component";
import NavbarMob2Component from "../components/navbar/NavbarMob2Component";
import AskComponent from "../components/ask/AskComponent";
import FooterComponent from "../components/footer/FooterComponent";

const mapStateToProps = (state) => {
  return {
    isAsked: state.isAsked,
  };
};

const Ask = (props) => {
  const { t } = useTranslation();
  return (
    <div>
      <Seo
        title={` ${t("title")} | ${t("sections.section3")}`}
        description={t("sections.description3")}
        pathSlug="ask"
      />
      <div className="d-none d-xl-block">
        <NavbarWeb2Component />
      </div>
      <div className="d-xl-none">
        <NavbarMob2Component />
      </div>
      <AskComponent
        isLoading={props.isAsked.isLoading}
        errMess={props.isAsked.errMess}
        isAsked={props.isAsked.isAsked}
      />
      <FooterComponent />
    </div>
  );
};

export default connect(mapStateToProps)(Ask);
