import React, { Component } from "react";
import { Container, Image, Col, Form, Button, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import axios from "axios";
import { getUserMsisdn } from "../../subscriptionFn";
import AskLoader from "./AskLoader";
import "./AskComponent.css";
export default class Ask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAsked: this.props.isAsked,
      isLoading: "ask.note5",
      question: "",
      alertState: this.props.isAsked,
      alertMessage: <Trans i18nKey={"ask.successMess"} />,
      alertColor: "success",
    };
    this.handleQuestionChange = this.handleQuestionChange.bind(this);
    this.submitQuestion = this.submitQuestion.bind(this);
  }

  handleQuestionChange(event) {
    this.setState({ question: event.target.value });
  }

  submitQuestion = (event) => {
    let msisdn = getUserMsisdn();
    this.setState({ isLoading: "subscription.subLoading" });
    event.preventDefault();

    axios({
      method: "post",
      url: process.env.REACT_APP_BASE_URL + process.env.REACT_APP_POST_QUESTION,
      headers: {
        "Content-Type": "application/json",
        apiKey: process.env.REACT_APP_API_KEY,
      },
      params: {
        userId: 1,
        question: this.state.question,
        mobile: msisdn,
      },
    })
      .then(
        (response) => {
          if (response.data.status === 200) {
            this.setState({
              isAsked: true,
              alertState: true,
              alertMessage: <Trans i18nKey={"ask.successMess"} />,
              alertColor: "success",
            });
          } else {
            this.setState({
              alertState: true,
              alertMessage: "Try Again Later",
              alertColor: "danger",
            });
          }
        },
        (error) => {
          this.setState({
            alertState: true,
            alertMessage: <Trans i18nKey={"ask.faliureMess"} />,
            alertColor: "danger",
          });
          var errmess = new Error(error.message);
          throw errmess;
        }
      )
      .catch((error) => error.message);
  };

  render() {
    if (this.props.isLoading || this.props.errMess) {
      return <AskLoader />;
    } else {
      return (
        <Container className="my-5 askWeb">
          <Col xs={12} lg={5} className="m-auto text-center p-0">
            <Image
              src="/img/ask.jpg"
              width={150}
              className="rounded-circle p-2"
            />
            <h5 className="text-uppercase fs-xxm color-primary-light text-center my-3">
              <Trans i18nKey="sections.section3" />
            </h5>
            <p className="text-center fs-md color-tertiary-dark">
              <Trans i18nKey="sections.description3" />
            </p>
            <p className="color-ffffff fs-sm text-center">
              <Trans i18nKey="ask.note1" />
            </p>
            <Alert
              className="p-2 fs-md"
              show={this.state.alertState ? true : false}
              variant={this.state.alertColor}
              transition={true}
            >
              {this.state.alertMessage}
            </Alert>
            <Form className="text-center" onSubmit={this.submitQuestion}>
              <Form.Group
                className={`${this.state.isAsked ? "d-none" : "d-block"}`}
              >
                <Form.Control
                  as="textarea"
                  rows={5}
                  className="border-radius-16 fs-md p-3"
                  value={this.state.phoneNo}
                  onChange={this.handleQuestionChange}
                />
              </Form.Group>

              <p className="fs-sm color-tertiary-dark text-center mt-2">
                <Trans i18nKey="ask.note3" />
              </p>

              <Button
                type="submit"
                value="Submit"
                className={`m-auto py-2 py-md-3 primary-btn fs-md w-75 text-uppercase ${
                  this.state.isAsked ? "d-none" : "d-block"
                }`}
              >
                <Trans i18nKey={this.state.isLoading} />
              </Button>
            </Form>
            <Link
              to=""
              className="fs-xm text-center color-primary-dark mt-3 d-block"
            >
              <Trans i18nKey="ask.note4" />
            </Link>
          </Col>
        </Container>
      );
    }
  }
}
