import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { HomeWorkout } from "./homeWorkout";
import { GymWorkout } from "./gymWorkout";
import { NutritionTips, NutritionVideos, AlyMazharTips } from "./nutrition";
import { Faq, IsAsked } from "./faq";
import { HomeFavorites, GymFavorites } from "./favorites";
import {
  CompetitionHeader,
  Competition,
  CompetitionUsers,
  CompetitionRanking,
  CompetitionUploadedVideos,
} from "./competition";

import { rate } from "./rate";

import { getUserProfile } from "./profile";
export const ConfigureStore = () => {
  const store = createStore(
    combineReducers({
      homeWorkout: HomeWorkout,
      gymWorkout: GymWorkout,
      nutritionVideos: NutritionVideos,
      nutritionTips: NutritionTips,
      alyMazharTips: AlyMazharTips,
      faq: Faq,
      homeFav: HomeFavorites,
      gymFav: GymFavorites,
      isAsked: IsAsked,
      uploadedVideos: CompetitionUploadedVideos,
      // competitionHeader: CompetitionHeader,
      // competition: Competition,
      // compUsers: CompetitionUsers,
      // compRanking: CompetitionRanking,
      userProfile: getUserProfile,
      rateState: rate,
    }),
    applyMiddleware(thunk)
  );
  // logger

  return store;
};
