import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Seo from "../components/Seo";
import NavbarWeb2Component from "../components/navbar/NavbarWeb2Component";
import NavbarMob2Component from "../components/navbar/NavbarMob2Component";
import BannerWebComponent from "../components/banner/BannerWebComponent";
import BannerMobComponent from "../components/banner/BannerMobComponent";
import VideoCardWebComponent from "../components/videos/VideoCardWebComponent";
import FooterComponent from "../components/footer/FooterComponent";

const mapStateToProps = (state) => {
  return {
    faq: state.faq,
  };
};

const Questions = (props) => {
  const { t } = useTranslation();

  return (
    <div>
      <Seo
        title={` ${t("title")} | ${t("sections.section4")}`}
        description={t("sections.description4")}
        pathSlug="questions"
      />

      <div className="d-none d-xl-block">
        <NavbarWeb2Component />
        <BannerWebComponent
          title={<Trans i18nKey="sections.section4" />}
          subTitle={<Trans i18nKey="sections.description4" />}
        />
      </div>
      <div className="d-xl-none">
        <NavbarMob2Component />
        <BannerMobComponent
          title={<Trans i18nKey="sections.section4" />}
          subTitle={<Trans i18nKey="sections.description4" />}
        />
      </div>
      <VideoCardWebComponent
        type="questions"
        colXsSize={12}
        colMdSize={6}
        colLgSize={4}
        isLoading={props.faq.isLoading}
        errMess={props.faq.errMess}
        videos={props.faq.faq}
      />

      <FooterComponent />
    </div>
  );
};
export default connect(mapStateToProps)(Questions);
