import Cookies from "universal-cookie";
const axios = require("axios");
const cookies = new Cookies();

export async function getHeaderParam() {
  return await axios({
    method: "get",
    url: "https://api.appcorp.online/new/HeaderEnrichemnt",
  }).then(
    (response) => {
      return response.data;
    },
    (error) => {
      var errmess = new Error(error.message);
      throw errmess;
    }
  );
}

export async function checkHeaderEnrichment(param) {
  return await axios({
    method: "post",
    url: process.env.REACT_APP_SUB_URL + process.env.REACT_APP_ET_HEADER,
    headers: {
      "Content-Type": "application/json",
      application_key: process.env.REACT_APP_API_KEY,
    },
    params: {
      headerParam: param,
    },
  }).then(
    (response) => {
      if (response.data.status === 200) {
        return response.data.user;
      } else {
        return null;
      }
    },
    (error) => {
      var errmess = new Error(error.message);
      throw errmess;
    }
  );
}

export async function checkSubId(subId) {
  return await axios({
    method: "post",
    url: process.env.REACT_APP_SUB_URL + process.env.REACT_APP_ET_SUBID,
    headers: {
      "Content-Type": "application/json",
      application_key: process.env.REACT_APP_API_KEY,
    },
    params: {
      subId: subId,
    },
  }).then(
    (response) => {
      if (response.data.status === 200) {
        return response.data.user;
      } else {
        return null;
      }
    },
    (error) => {
      var errmess = new Error(error.message);
      throw errmess;
    }
  );
}
export async function checkUserSubscription(msisdn) {
  let userToken = getUserToken();
  return await axios({
    method: "post",
    url: process.env.REACT_APP_SUB_URL + process.env.REACT_APP_CHECK_SUB,
    headers: {
      "Content-Type": "application/json",
      application_key: process.env.REACT_APP_API_KEY,
      token: userToken,
    },
    params: {
      msisdn: msisdn,
    },
  }).then(
    (response) => {
      if (
        response.data.status === 1 &&
        response.data.message === "Subscriber."
      ) {
        return true;
      } else if (response.data.status === -6 || response.data.status === -9) {
        cookies.set("fitnessCookies", "", {
          path: "/",
          maxAge: "0",
        });
        return false;
      } else if (response.data.status === 403 || response.data.status === 400) {
        return false;
      } else {
        return false;
      }
    },
    (error) => {
      var errmess = new Error(error.message);
      throw errmess;
    }
  );
}

export async function updateUserToken(msisdn) {
  return await axios({
    method: "post",
    url: process.env.REACT_APP_SUB_URL + process.env.REACT_APP_UPDATE_TOKEN,
    headers: {
      "Content-Type": "application/json",
      application_key: process.env.REACT_APP_API_KEY,
    },
    params: {
      msisdn: msisdn,
    },
  }).then(
    (response) => {
      if (response.data.status === 200 && response.data.token !== null) {
        let userData = `${response.data.token},${msisdn}`;
        cookies.set("fitnessCookies", userData, {
          path: "/",
          maxAge: "43200",
        });
        window.location.href = "/";
        return true;
      } else if (response.data.status === 403 || response.data.status === 400) {
        return false;
      }
    },
    (error) => {
      var errmess = new Error(error.message);
      throw errmess;
    }
  );
}

export function getUserMsisdn() {
  if (cookies.get("fitnessCookies")) {
    let userMsisdn = cookies.get("fitnessCookies").split(",");
    return userMsisdn[1];
  }
  return null;
}

export function getUserToken() {
  if (cookies.get("fitnessCookies")) {
    let userToken = cookies.get("fitnessCookies").split(",");
    return userToken[0];
  }
  return null;
}
