import React from "react";
import { useTranslation } from "react-i18next";
import NavbarWeb2Component from "../components/navbar/NavbarWeb2Component";
import NavbarMob2Component from "../components/navbar/NavbarMob2Component";
import TermsAndConditionsComponent from "../components/TermsAndConditions/TermsAndConditionsComponent";
import FooterComponent from "../components/footer/FooterComponent";
import Seo from "../components/Seo";

const TermsAndConditions = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Seo
        title={` ${t("title")} | ${t("Terms")} `}
        description={t("intro")}
        pathSlug="intro"
      />
      <div className="d-none d-xl-block">
        <NavbarWeb2Component />
      </div>
      <div className="d-xl-none">
        <NavbarMob2Component />
      </div>
      <TermsAndConditionsComponent />
      <FooterComponent />
    </div>
  );
};

export default TermsAndConditions;
