import React, { Component } from "react";
import { Container, Col, Button, Form, Alert } from "react-bootstrap";
import NavbarWeb1Component from "../components/navbar/NavbarWeb1Component";
import { Trans, withTranslation } from "react-i18next";
import Seo from "../components/Seo";
import axios from "axios";
import Cookies from "universal-cookie";
import { getUserMsisdn, checkUserSubscription } from "../subscriptionFn";

import i18n from "../i18n";

class SubscriptionComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appKey: null,
      isLoading: false,
      phoneNo: "",
      pinNo: "",
      phoneForm: true,
      pinForm: false,
      alertMessage: null,
      alertState: false,
      alertColor: null,
      redirect: null,
      isSubscriber: null,
      lang: null,
    };

    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handlePinChange = this.handlePinChange.bind(this);
    this.submitSendCode = this.submitSendCode.bind(this);
    this.submitVerifyCode = this.submitVerifyCode.bind(this);
    this.handleForm = this.handleForm.bind(this);
  }

  componentDidMount() {
    const msisdn = getUserMsisdn();

    if (msisdn != null) {
      const state = checkUserSubscription(msisdn);

      state.then((res) => {
        if (res === true) {
          this.setState({ redirect: true });
        } else {
          this.setState({ redirect: false });
        }
      });
    } else {
      this.setState({ redirect: false });
    }
  }
  handlePhoneChange(event) {
    this.setState({ phoneNo: event.target.value });
  }

  handlePinChange(event) {
    this.setState({ pinNo: event.target.value });
  }

  submitSendCode(event) {
    this.setState({
      isLoading: true,
    });

    event.preventDefault();

    axios({
      method: "post",
      url: process.env.REACT_APP_SUB_URL + process.env.REACT_APP_SEND_CODE,
      params: { msisdn: "2" + this.state.phoneNo },

      headers: {
        "Content-Type": "application/json",
        application_key: process.env.REACT_APP_API_KEY,
      },
    })
      .then(
        (response) => {
          if (response.data.status === 200 || response.data.status === -3) {
            this.setState({
              phoneForm: false,
              pinForm: true,
              alertState: false,
            });
          } else if (response.data.status === -1) {
            this.setState({
              alertState: true,
              alertMessage: <Trans i18nKey="subscription.invalidPhoneNo" />,
              alertColor: "danger",
            });
          }
          this.setState({
            isLoading: false,
          });
        },
        (error) => {
          var errmess = new Error(error.message);
          throw errmess;
        }
      )
      .catch((error) => error.message);
  }

  submitVerifyCode(event) {
    this.setState({
      isLoading: true,
    });
    event.preventDefault();
    axios({
      method: "post",
      url: process.env.REACT_APP_SUB_URL + process.env.REACT_APP_CHECK_CODE,
      params: {
        msisdn: "2" + this.state.phoneNo,
        pinCode: this.state.pinNo,
      },
      headers: {
        "Content-Type": "application/json",
        application_key: process.env.REACT_APP_API_KEY,
      },
    })
      .then(
        (response) => {
          if (response.data.status === 200) {
            const cookies = new Cookies(),
              userData = `${response.data.token},2${this.state.phoneNo}`;
            cookies.set("fitnessCookies", userData, {
              path: "/",
              maxAge: "43200",
            });
            this.setState({ redirect: true });
          }
          if (response.data.status === -2) {
            this.setState({
              alertState: true,
              alertMessage: <Trans i18nKey="subscription.invalidPinCode" />,
              alertColor: "danger",
            });
          } else if (response.data.status === -4) {
            this.setState({
              alertState: true,
              alertMessage: <Trans i18nKey="subscription.limitExceed" />,
              alertColor: "danger",
            });
          } else if (response.data.status === -6) {
            window.location.href =
              i18n.language === "en"
                ? process.env.REACT_APP_SURL_EN
                : process.env.REACT_APP_SURL_AR;
          }
          this.setState({
            isLoading: false,
          });
        },
        (error) => {
          this.setState({
            isLoading: false,
          });
          var errmess = new Error(error.message);
          throw errmess;
        }
      )

      .catch((error) => error.message);
  }

  handleForm(i18n) {
    if (this.state.phoneForm) {
      return (
        <Form className="text-center" onSubmit={this.submitSendCode}>
          <Form.Group>
            <Form.Control
              className="fs-sm border-radius-10 py-2 py-md-4"
              type="number"
              placeholder={
                i18n.language === "en" || i18n.language === "en-US"
                  ? "Please enter your number to get your code"
                  : "من فضلك ادخل رقم تليفونك لإرسال الرمز الخاص بك"
              }
              value={this.state.phoneNo}
              onChange={this.handlePhoneChange}
            />
          </Form.Group>
          <Button type="submit" value="Submit" className="primary-btn w-100">
            {this.state.isLoading ? (
              <Trans i18nKey="subscription.subLoading" />
            ) : (
              <Trans i18nKey="subscription.subBtnText" />
            )}
          </Button>
        </Form>
      );
    } else if (this.state.pinForm) {
      return (
        <div>
          <Form className="text-center" onSubmit={this.submitVerifyCode}>
            <Form.Group>
              <Form.Control
                className="fs-sm border-radius-10 py-2 py-md-4"
                as="input"
                type="number"
                value={this.state.pinNo}
                onChange={this.handlePinChange}
                // placeholder={t("subscription.pinPlacehlder")}
                placeholder={
                  i18n.language === "en" || i18n.language === "en-US"
                    ? "Please enter your pin code that you received in message"
                    : "من فضلك ادخل رمز التأكيد الذي استلمته في رسالة الآن"
                }
              />
            </Form.Group>
            <Button type="submit" className="primary-btn w-100">
              {this.state.isLoading ? (
                <Trans i18nKey="subscription.subLoading" />
              ) : (
                <Trans i18nKey="subscription.subBtnText" />
              )}
            </Button>
          </Form>
        </div>
      );
    }
  }

  render() {
    const { i18n, t } = this.props;
    const { redirect } = this.state;

    if (redirect) {
      window.location.href = "/";
    } else if (redirect === null) {
      return <div></div>;
    } else {
      return (
        <div>
          <Seo
            title={`Etisalat Fitness | Subscribe Now`}
            description={t("intro")}
            pathSlug="subscribe"
          />

          <Container className="p-0 intro-section intro-video" fluid>
            <Container>
              <NavbarWeb1Component />
              <Col lg={5} className="m-auto text-center p-0">
                <div className="subscription-image"></div>
                {/* <!--Start Welcome Div--> */}
                <div className="welcome pt-3 px-3 text-center">
                  <p className="mute-text fs-xm mb-0 text-center">
                    <Trans i18nKey="subscription.title1" />
                  </p>
                  <p className="mute-text fs-xm text-center">
                    <Trans i18nKey="subscription.title2" />
                  </p>
                  <p className="text-black fs-md m-0 text-center">
                    <Trans i18nKey="subscription.subTitle1" />
                  </p>
                  <p className="color-primary-light fs-md text-center"></p>

                  <Alert
                    className="p-2 fs-md"
                    show={this.state.alertState}
                    variant={this.state.alertColor}
                    transition={true}
                  >
                    {this.state.alertMessage}
                  </Alert>

                  {this.handleForm(i18n)}
                </div>
                {/* <!--End Welcome Div--> */}
              </Col>
            </Container>
          </Container>
        </div>
      );
    }
  }
}

export default withTranslation("namespace")(SubscriptionComponent);
