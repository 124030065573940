import React from "react";
import { Container, Col, Button } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Seo from "../components/Seo";
import ReactPlayer from "react-player";
import NavbarWeb1Component from "../components/navbar/NavbarWeb1Component";

const Intro = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Seo
        title={` ${t("title")} | ${t("intro")} `}
        description={t("intro")}
        pathSlug="intro"
      />
      <Container className="p-0 intro-section intro-video" fluid>
        <Container>
          <NavbarWeb1Component />
          <Col lg={6} className="m-auto text-center p-0">
            <p className="text-capitalize text-center m-3 m-md-5">
              <Trans i18nKey="intro"></Trans>
            </p>
            <ReactPlayer
              stopOnUnmount={false}
              playIcon={<div className="play-btn"></div>}
              className="border-radius-10 react-player"
              url="/vid/EtisalatFitness_IntroVideo.mp4"
              width="100%"
              height="100%"
              controls={true}
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload", //<- this is the important bit
                  },
                },
              }}
            />
            <Button
              className="my-4 primary-btn  w-75"
              as={Link}
              to="/subscribe"
            >
              <Trans i18nKey="start"></Trans>
            </Button>
          </Col>
        </Container>
      </Container>
    </div>
  );
};

export default Intro;
