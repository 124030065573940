import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import "./BannerCompetitionComponent.css";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const BannerCompetitionComponentLoader = () => {
  return (
    <Container fluid className="bg-secondary-dark">
      <Container className="py-4 p-0">
        <SkeletonTheme color="#202020" highlightColor="#444">
          <Row>
            <Col xs={12} md={9} xl={10}>
              <Row>
                <Col xs={12} md={2} xl={1} className="p-2 m-auto">
                  <div className="eclipse mx-2"></div>
                </Col>
                <Col>
                  <div className="my-auto mx-2">
                    <p className="color-primary-light mr-auto my-2 fs-xm">
                      <Skeleton count={1} width={200} />
                    </p>
                    <p className=" mr-auto my-auto fs-md color-tertiary">
                      <Skeleton count={2} />
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col
              xs={12}
              md={3}
              xl={2}
              className="m-auto calendar-date-container"
            >
              <div className="m-auto d-flex">
                <Skeleton width={50} height={100} className="mx-2" />
                <Skeleton width={50} height={100} />
              </div>
            </Col>
          </Row>
        </SkeletonTheme>
      </Container>
    </Container>
  );
};

export default BannerCompetitionComponentLoader;
