import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import {ReactComponent as Gym} from '../../assets/img/icons/gym.svg'
import {ReactComponent as Workout} from '../../assets/img/icons/workout.svg'
import {ReactComponent as Diet} from '../../assets/img/icons/diet.svg'
import {ReactComponent as Conversation} from '../../assets/img/icons/conversation.svg'
import {ReactComponent as Request} from '../../assets/img/icons/request.svg'

import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import "./HomeWebComponent.css";

const HomeWebComponent = () => {
  return (
    <Container className="homeWebContainer">
      <div className="mt-4">
        <p className="text-center">
          <Trans i18nKey="welcome" />
        </p>
        <Col xs={12} className="py-5">
          <Row className="justify-content-center m-auto row-flex">
            <Col xs={4} className="my-5">
              <Link to={"/gym-workout"}>
                <Card className="m-auto h-100">
                  <div className="circle-icon">
                    <Gym />
                  </div>
                  <Card.Body className="mt-5">
                    <Card.Title className="text-center">
                      <Trans i18nKey="sections.section2" />
                    </Card.Title>
                    <Card.Text className="text-center m-2 fs-md">
                      <Trans i18nKey="sections.description2" />
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
            <Col xs={4} className="my-5">
              <Link to={"/home-workout"}>
                <Card className="m-auto h-100">
                  <div className="circle-icon">
                    <Workout />
                  </div>
                  <Card.Body className="mt-5">
                    <Card.Title className="text-center">
                      <Trans i18nKey="sections.section1" />
                    </Card.Title>
                    <Card.Text className="text-center m-2 fs-md">
                      <Trans i18nKey="sections.description1" />
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
            <Col xs={4} className="my-5">
              <Link to="/nutrition-tips">
                <Card className="m-auto h-100">
                  <div className="circle-icon">
                    <Diet />
                  </div>
                  <Card.Body className="mt-5">
                    <Card.Title className="text-center">
                      <Trans i18nKey="sections.section5" />
                    </Card.Title>
                    <Card.Text className="text-center m-2 fs-md">
                      <Trans i18nKey="sections.description5" />
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
            <Col xs={4} className="my-5">
              <Link to="/aly-mazhar-tips">
                <Card className="m-auto h-100">
                  <div className="circle-icon">
                    <Diet />
                  </div>
                  <Card.Body className="mt-5">
                    <Card.Title className="text-center">
                      <Trans i18nKey="sections.section7" />
                    </Card.Title>
                    <Card.Text className="text-center m-2 fs-md">
                      <Trans i18nKey="sections.description7" />
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
            <Col xs={4} className="my-5">
              <Link to="/ask">
                <Card className="m-auto h-100">
                  <div className="circle-icon">
                    <Conversation />
                  </div>
                  <Card.Body className="mt-5">
                    <Card.Title className="text-center">
                      <Trans i18nKey="sections.section3" />
                    </Card.Title>
                    <Card.Text className="text-center m-2 fs-md">
                      <Trans i18nKey="sections.description3" />
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
            <Col xs={4} className="my-5">
              <Link to="/questions">
                <Card className="m-auto h-100">
                  <div className="circle-icon">
                    <Request />
                  </div>
                  <Card.Body className="mt-5">
                    <Card.Title className="text-center">
                      <Trans i18nKey="sections.section4" />
                    </Card.Title>
                    <Card.Text className="text-center m-2 fs-md">
                      <Trans i18nKey="sections.description4" />
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          </Row>
        </Col>
      </div>
    </Container>
  );
};

export default HomeWebComponent;
