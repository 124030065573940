import React from "react";
import { Container, Col, Button } from "react-bootstrap";
import NavbarWeb1Component from "../components/navbar/NavbarWeb1Component";
import { Trans, useTranslation } from "react-i18next";

const HeaderSubComponent = () => {
  const { i18n } = useTranslation();

  return (
    <Container className="p-0 intro-section" fluid>
      <Container>
        <NavbarWeb1Component />
        <Col lg={5} className="m-auto text-center p-0">
          <div className="subscription-image"></div>
          {/* <!--Start Welcome Div--> */}
          <div className="welcome pt-3 px-3 text-center">
            <p className="mute-text fs-xm mb-0 text-center">
              <Trans i18nKey="subscription.title3" />
            </p>
            <p className="mute-text fs-xm mb-0 text-center">
              <Trans i18nKey="subscription.title1" />
            </p>
            <p className="mute-text fs-xm text-center">
              <Trans i18nKey="subscription.title2" />
            </p>
            <p className="text-black fs-md m-0 text-center">
              <Trans i18nKey="subscription.subTitle1" />
            </p>
            <p className="primary-color fs-md text-center"></p>
          </div>
          {/* <!--End Welcome Div--> */}
          <Button
            type="submit"
            value="Submit"
            className="my-2 py-2 primary-btn w-100"
            onClick={() =>
              (window.location.href =
                i18n.language === "en"
                  ? process.env.REACT_APP_SURL_EN
                  : process.env.REACT_APP_SURL_AR)
            }
          >
            <Trans i18nKey="subscription.subBtnText2" />
          </Button>
        </Col>
      </Container>
    </Container>
  );
};

export default HeaderSubComponent;
