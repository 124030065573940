export const HOME_WORKOUT_LOADING = "HOME_WORKOUT_LOADING";
export const HOME_WORKOUT_FAILED = "HOME_WORKOUT_FAILED";
export const ADD_HOME_WORKOUT = "ADD_HOME_WORKOUT";

export const GYM_WORKOUT_LOADING = "GYM_WORKOUT_LOADING";
export const GYM_WORKOUT_FAILED = "GYM_WORKOUT_FAILED";
export const ADD_GYM_WORKOUT = "ADD_GYM_WORKOUT";

export const NUTRITION_VIDEOS_LOADING = "NUTRITION_VIDEOS_LOADING";
export const NUTRITION_VIDEOS_FAILED = "NUTRITION_VIDEOS_FAILED";
export const ADD_NUTRITION_VIDEOS = "ADD_NUTRITION_VIDEOS";

export const ALYMAZHAR_TIPS_LOADING = "ALYMAZHAR_TIPS_LOADING";
export const ALYMAZHAR_TIPS_FAILED = "ALYMAZHAR_TIPS_FAILED";
export const ADD_ALYMAZHAR_TIPS = "ADD_ALYMAZHAR_TIPS";

export const NUTRITION_TIPS_LOADING = "NUTRITION_TIPS_LOADING";
export const NUTRITION_TIPS_FAILED = "NUTRITION_TIPS_FAILED";
export const ADD_NUTRITION_TIPS = "ADD_NUTRITION_TIPS";

export const FAQ_LOADING = "FAQ_LOADING";
export const FAQ_FAILED = "FAQ_FAILED";
export const ADD_FAQ = "ADD_FAQ";

export const HOME_FAVORITES_LOADING = "HOME_FAVORITES_LOADING";
export const HOME_FAVORITES_FAILED = "HOME_FAVORITES_FAILED";
export const ADD_HOME_FAVORITES = "ADD_HOME_FAVORITES";

export const GYM_FAVORITES_LOADING = "GYM_FAVORITES_LOADING";
export const GYM_FAVORITES_FAILED = "GYM_FAVORITES_FAILED";
export const ADD_GYM_FAVORITES = "ADD_GYM_FAVORITES";

export const UPLOADED_VIDEO_LOADING = "UPLOADED_VIDEO_LOADING";
export const UPLOADED_VIDEO_FAILED = "UPLOADED_VIDEO_FAILED";
export const ADD_UPLOADED_VIDEO = "ADD_UPLOADED_VIDEO";

export const ADD_MORE_TIPS = "ADD_MORE_TIPS";

export const IS_ASKED_LOADING = "IS_ASKED_LOADING";
export const IS_ASKED_FAILED = "IS_ASKED_FAILED";
export const ADD_IS_ASKED = "ADD_IS_ASKED";

export const COMPETITION_HEADER_LOADING = "COMPETITION_HEADER_LOADING";
export const COMPETITION_HEADER_FAILED = "COMPETITION_HEADER_FAILED";
export const ADD_COMPETITION_HEADER = "ADD_COMPETITION_HEADER";

export const COMPETITION_LOADING = "COMPETITION_LOADING";
export const COMPETITION_FAILED = "COMPETITION_FAILED";
export const ADD_COMPETITION = "ADD_COMPETITION";

export const COMPETITIONUSERS_LOADING = "COMPETITIONUSERS_LOADING";
export const COMPETITIONUSERS_FAILED = "COMPETITIONUSERS_FAILED";
export const ADD_COMPETITIONUSERS = "ADD_COMPETITIONUSERS";

export const COMPRANKING_LOADING = "COMPRANKING_LOADING";
export const COMPRANKING_FAILED = "COMPRANKING_FAILED";
export const ADD_COMPRANKING = "ADD_COMPRANKING";

export const ADD_FAVORITE = "ADD_FAVORITE";
export const REMOVE_FAVORITE = "REMOVE_FAVORITE";

export const UPDATE_HOME_DONE = "UPDATE_HOME_DONE";
export const UPDATE_HOME_DONE_LOADING = "UPDATE_HOME_DONE_LOADING";
export const UPDATE_GYM_DONE = "UPDATE_GYM_DONE";
export const UPDATE_GYM_DONE_LOADING = "UPDATE_GYM_DONE_LOADING";
export const UPDATE_GYM_WORKOUTS_FAVORITE = "UPDATE_GYM_WORKOUTS_FAVORITE";
export const UPDATE_GYM_WORKOUTS_FAVORITE_LOADING =
  "UPDATE_GYM_WORKOUTS_FAVORITE_LOADING";
export const UPDATE_HOME_WORKOUTS_FAVORITE = "UPDATE_HOME_WORKOUTS_FAVORITE";
export const UPDATE_HOME_WORKOUTS_FAVORITE_LOADING =
  "UPDATE_HOME_WORKOUTS_FAVORITE_LOADING";

export const UPDATE_HOME_FAVORITE_ADD = "UPDATE_HOME_FAVORITE_ADD";
export const UPDATE_HOME_FAVORITE_REMOVE = "UPDATE_HOME_FAVORITE_REMOVE";
export const UPDATE_HOME_FAVORITE_LOADING = "UPDATE_HOME_FAVORITE_LOADING";
export const UPDATE_GYM_FAVORITE_LOADING = "UPDATE_GYM_FAVORITE_LOADING";
export const UPDATE_GYM_FAVORITE_ADD = "UPDATE_GYM_FAVORITE_ADD";
export const UPDATE_GYM_FAVORITE_REMOVE = "UPDATE_GYM_FAVORITE_LOADING_REMOVE";

export const UPDATE_PROFILE_LOADING = "UPDATE_PROFILE_LOADING";
export const UPDATE_PROFILE_FAILED = "UPDATE_PROFILE_FAILED";
export const ADD_UPDATE_PROFILE = "ADD_UPDATE_PROFILE";

export const ADD_RATE = "ADD_RATE";
export const RATE_FAILED = "RATE_FAILED";
export const RATE_LOADING = "RATE_LOADING";
