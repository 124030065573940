import React, { useRef, useState } from "react";
import axios from "axios";
import { Trans, useTranslation } from "react-i18next";
import { Container, Col, ProgressBar } from "react-bootstrap";
import { getUserMsisdn } from "../../../subscriptionFn";
import { FileDrop } from "react-file-drop";
import { BsFillCloudFill } from "react-icons/bs";
import { FaArrowCircleUp } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import "./UploadComponent.css";

const UploadComponent = (props) => {
  let history = useHistory();
  useTranslation();
  const [progress, setProgress] = useState();
  const [disabledBtn, setDisabled] = useState();
  // File Upload
  const fileInputRef = useRef(null);

  const onFileInputChange = (event, props) => {
    const { files } = event.target;

    // do something with your files...
    event.preventDefault();
    if (props.type === "video") {
      handleUploadVideo(files[0]);
    } else {
      props.updateImage(files[0]);
    }
  };

  const handleUploadVideo = (file) => {
    setDisabled("disabled");
    let form_data = new FormData();
    form_data.append("msisdn", getUserMsisdn());
    form_data.append("video", file);
    form_data.append("competitionId", props.challengeId);

    let url = "";
    if (props.trials === "1") {
      url =
        process.env.REACT_APP_BASE_URL +
        process.env.REACT_APP_UPDATE_USER_COMPETITION;
    } else {
      url =
        process.env.REACT_APP_BASE_URL +
        process.env.REACT_APP_ADD_USER_COMPETITION;
    }

    axios
      .post(url, form_data, {
        headers: {
          "content-type": "multipart/form-data",
          apiKey: process.env.REACT_APP_API_KEY,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((res) => {
        if (res.data.message === "Success" && res.data.status === 200) {
          history.push("/profile");
        } else {
          setDisabled("");
        }
      })
      .catch((err) => {
        setProgress();
        setDisabled("");
      });
  };

  // File Upload

  return (
    <Container
      style={{ background: "#212121", borderRadius: 32 }}
      className="p-0"
    >
      <Col xs={12} className="uploadImageContainer p-3">
        <div className="h-100 fileContainer">
          {/* <Alert show={true} variant="success" className="w-100 m-auto">
            <p className="fs-md m-auto">ERROR</p>
          </Alert>
          <Alert show={true} variant="danger" className="w-100 m-auto">
            <p className="fs-md m-auto">ERROR</p>
          </Alert> */}
          {progress && (
            <ProgressBar
              now={progress}
              label={`${progress}%`}
              className="border-radius-10 mx-3 mt-3"
            />
          )}
          <FileDrop
            onDrop={
              props.type === "video"
                ? (files, event) => handleUploadVideo(files[0])
                : (files, event) => props.updateImage(files[0])
            }
          >
            <div className="upload-icon">
              <BsFillCloudFill className="fs-xxl color-tertiary-light" />
              <FaArrowCircleUp className="color-primary-light upload-arrow" />
            </div>
            <h4 className="color-primary-dark pt-4 fs-xxm font-weight-bold">
              <Trans i18nKey={props.title} />
            </h4>
            <p className="fs-md text-center color-tertiary p-3">
              <Trans i18nKey={props.description} />
            </p>

            <label
              htmlFor="filePicker"
              className={`btn btn-primary primary-btn fs-md d-block m-auto ${
                disabledBtn ? "disabled" : null
              }`}
            >
              <Trans i18nKey="upload.btn_upload" />
            </label>
            <input
              id="filePicker"
              style={{ visibility: "hidden" }}
              type={"file"}
              accept={props.accept}
              onChange={(e) => onFileInputChange(e, props)}
              ref={fileInputRef}
              disabled={disabledBtn ? "disabled" : null}
            />
          </FileDrop>
        </div>
      </Col>
    </Container>
  );
};

export default UploadComponent;
