import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import UserImgAndRatingComponentLoader from "../user/UserImgAndRatingComponentLoader";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "./RankingComponent.css";

const RankingComponentLoader = () => {
  return (
    <Container className="rankingContainer" fluid="lg">
      <SkeletonTheme color="#202020" highlightColor="#444">
        <Col xs={12} className="bg-secondary-dark p-3 my-3">
          <Row>
            <Col xs={9} md={10} className="m-auto">
              <UserImgAndRatingComponentLoader />
            </Col>
            <Col xs={3} md={2} className="ml-auto my-auto">
              <Skeleton count={1} />
            </Col>
          </Row>
        </Col>
        <Col xs={12} className="bg-secondary-dark p-3 my-3">
          <Row>
            <Col xs={9} md={10} className="m-auto">
              <UserImgAndRatingComponentLoader />
            </Col>
            <Col xs={3} md={2} className="ml-auto my-auto">
              <Skeleton count={1} />
            </Col>
          </Row>
        </Col>
        <Col xs={12} className="bg-secondary-dark p-3 my-3">
          <Row>
            <Col xs={9} md={10} className="m-auto">
              <UserImgAndRatingComponentLoader />
            </Col>
            <Col xs={3} md={2} className="ml-auto my-auto">
              <Skeleton count={1} />
            </Col>
          </Row>
        </Col>
        <Col xs={12} className="bg-secondary-dark p-3 my-3">
          <Row>
            <Col xs={9} md={10} className="m-auto">
              <UserImgAndRatingComponentLoader />
            </Col>
            <Col xs={3} md={2} className="ml-auto my-auto">
              <Skeleton count={1} />
            </Col>
          </Row>
        </Col>
      </SkeletonTheme>
    </Container>
  );
};

export default RankingComponentLoader;
