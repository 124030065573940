import React from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "./CompetitionComponent.css";

const CompetitionComponentLoader = () => {
  return (
    <Container className="competitionContainer" fluid="lg">
      <SkeletonTheme color="#202020" highlightColor="#444">
        <Row>
          <Col xs={12}>
            <Card className="border-radius-10 my-3">
              <Row noGutters>
                <Col xs={12} md={5}>
                  <Skeleton height={270} />
                </Col>
                <Col className="m-auto">
                  <Card.Body className="mx-2">
                    <Card.Title className="fs-xm text-uppercase color-tertiary-light">
                      <Skeleton count={1} />
                    </Card.Title>
                    <small className="color-tertiary-dark">
                      <Skeleton count={1} />
                    </small>
                    <Card.Title className="text-capitalize fs-xm  color-primary-light">
                      <Skeleton count={1} />
                    </Card.Title>
                    <Card.Text className="fs-md color-tertiary">
                      <Skeleton count={3} />
                    </Card.Text>
                    <Row className="justify-content-end mb-2 mb-lg-auto">
                      <Col xs={6}>
                        <Skeleton height={40} />
                      </Col>
                      <Col xs={6}>
                        <Skeleton height={40} />
                      </Col>
                    </Row>
                  </Card.Body>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Card className="border-radius-10 my-3">
              <Row noGutters>
                <Col xs={12} md={5}>
                  <Skeleton height={270} />
                </Col>
                <Col className="m-auto">
                  <Card.Body className="mx-2">
                    <Card.Title className="fs-xm text-uppercase color-tertiary-light">
                      <Skeleton count={1} />
                    </Card.Title>
                    <small className="color-tertiary-dark">
                      <Skeleton count={1} />
                    </small>
                    <Card.Title className="text-capitalize fs-xm  color-primary-light">
                      <Skeleton count={1} />
                    </Card.Title>
                    <Card.Text className="fs-md color-tertiary">
                      <Skeleton count={3} />
                    </Card.Text>
                    <Row className="justify-content-end mb-2 mb-lg-auto">
                      <Col xs={6}>
                        <Skeleton height={40} />
                      </Col>
                      <Col xs={6}>
                        <Skeleton height={40} />
                      </Col>
                    </Row>
                  </Card.Body>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </SkeletonTheme>
    </Container>
  );
};

export default CompetitionComponentLoader;
