import React from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./LangComponent.css";

const LangComponent = () => {
  const { i18n } = useTranslation();

  window.document.documentElement.lang = i18n.language;

  const changeLanguage = (e) => {
    i18n.changeLanguage(e);
  };

  return (
    <div className="lang-btn m-auto">
      <DropdownButton
        alignRight
        title={i18n.language}
        id="dropdown-menu-align-right"
        onSelect={changeLanguage}
        className="lang-btn"
      >
        <Dropdown.Item eventKey="en" className="fs-md">
          EN
        </Dropdown.Item>
        <Dropdown.Item eventKey="ar" className="fs-md">
          AR
        </Dropdown.Item>
      </DropdownButton>
    </div>
  );
};

export default LangComponent;
