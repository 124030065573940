import React, { useEffect, Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import {
  addDone,
  fetchHomeWorkout,
  fetchGymWorkout,
  fetchNutrition,
  fetchFaq,
  fetchHomeFavorites,
  fetchGymFavorites,
  fetchUploadedVideos,
  fetchIsAsked,
  // fetchCompetitionHeader,
  // fetchCompetition,
  // fetchCompetitionUsers,
  // fetchCompetitionRanking,
  fetchUserProfile,
} from "./redux/ActionCreators";
import {
  checkHeaderEnrichment,
  getUserMsisdn,
  checkUserSubscription,
  checkSubId,
  updateUserToken,
  getHeaderParam,
} from "./subscriptionFn";
import Intro from "./pages/Intro";
import TermsAndConditions from "./pages/TermsAndConditions";
import Subscription from "./pages/Subscription";
import HomeComponent from "./pages/Home";
import HomeWorkout from "./pages/HomeWorkout";
import GymWorkout from "./pages/GymWorkout";
import Questions from "./pages/Questions";
import NutritionTips from "./pages/NutritionTips";
import NutritionEpisodes from "./pages/NutritionEpisodes";
import AlyMazharTips from "./pages/AlyMazharTips";
import Profile from "./pages/Profile";
import Ask from "./pages/Ask";
import Challenges from "./pages/Challenges";
import CompetitionRanking from "./pages/CompetitionRanking";
import UsersVideos from "./pages/UsersVideos";
import VideoDetails from "./pages/VideoDetails";
import Upload from "./pages/Upload";
import HeaderSubComponent from "./pages/HeaderSubComponent";
import HomeLoader from "./pages/HomeLoader";

const mapDispatchToProps = (dispatch) => ({
  addDone: () => dispatch(addDone()),
  fetchHomeWorkout: () => dispatch(fetchHomeWorkout()),
  fetchGymWorkout: () => dispatch(fetchGymWorkout()),
  fetchNutritionVideo: (videos) => dispatch(fetchNutrition(videos)),
  fetchNutritionTips: (tips) => dispatch(fetchNutrition(tips)),
  fetchAlyMazharTips: (amt) => dispatch(fetchNutrition(amt)),
  fetchFaq: () => dispatch(fetchFaq()),
  fetchHomeFavorites: () => dispatch(fetchHomeFavorites()),
  fetchGymFavorites: () => dispatch(fetchGymFavorites()),
  fetchUploadedVideos: () => dispatch(fetchUploadedVideos()),
  fetchIsAsked: () => dispatch(fetchIsAsked()),
  // fetchCompetitionHeader: () => dispatch(fetchCompetitionHeader()),
  // fetchCompetition: () => dispatch(fetchCompetition()),
  // fetchCompetitionUsers: () => dispatch(fetchCompetitionUsers()),
  // fetchCompetitionRanking: () => dispatch(fetchCompetitionRanking()),
  fetchUserProfile: () => dispatch(fetchUserProfile()),
});

const MainComponent = (props) => {
  useEffect(() => {
    if (getUserMsisdn() !== null) {
      props.fetchUserProfile();
      props.fetchFaq();
      props.fetchHomeWorkout();
      props.fetchGymWorkout();
      props.fetchNutritionVideo("VIDEOS");
      props.fetchNutritionTips("TIPS");
      props.fetchNutritionTips("AMT");
      props.fetchFaq();
      props.fetchIsAsked();
      props.fetchHomeFavorites();
      props.fetchGymFavorites();
      props.fetchUploadedVideos();
      // props.fetchCompetitionHeader();
      // props.fetchCompetition();
      // props.fetchCompetitionUsers();
      // props.fetchCompetitionRanking();
    }
  }, []);
  return <MainRoutes />;
};

const MainRoutes = () => (
  <Switch>
    <Route path="/intro" component={Intro} exact />
    <Route path="/subscribe" component={Subscription} exact />
    <Route path="/welcome" component={HeaderSubComponent} exact />
    <Route path="/terms-conditions" component={TermsAndConditions} exact />
    <ProtectedRoute path="/" component={HomeComponent} exact />
    <ProtectedRoute path="/home-workout" component={HomeWorkout} exact />
    <ProtectedRoute path="/gym-workout" component={GymWorkout} exact />
    <ProtectedRoute path="/ask" component={Ask} exact />
    <ProtectedRoute path="/questions" component={Questions} exact />
    <ProtectedRoute path="/nutrition-tips" component={NutritionTips} exact />
    <ProtectedRoute
      path="/nutrition-episodes"
      component={NutritionEpisodes}
      exact
    />
    <ProtectedRoute path="/aly-mazhar-tips" component={AlyMazharTips} exact />
    {/* <ProtectedRoute path="/challenges" component={Challenges} exact /> */}
    {/* <ProtectedRoute path="/ranking" component={CompetitionRanking} exact /> */}
    {/* <ProtectedRoute path="/users/challenges" component={UsersVideos} exact /> */}
    <ProtectedRoute path="/video/:type/:id" component={VideoDetails} />
    <ProtectedRoute path="/upload/:id/:trials" component={Upload} exact />
    <ProtectedRoute path="/profile" component={Profile} exact />
  </Switch>
);

class ProtectedRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubscriber: false,
      isLoading: true,
      isHeader: false,
      headerParam: null,
    };
  }
  componentDidMount() {
    let msisdn = getUserMsisdn(),
      state;

    if (msisdn != null) {
      state = checkUserSubscription(msisdn);
      state.then((res) => {
        if (res === true) {
          this.setState({ isSubscriber: true, isLoading: false });
        }
      });
    } else {
      let paramFn = getHeaderParam();
      let type,
        state,
        userMsisdn = null;

      paramFn.then((res) => {
        if (res !== "") {
          this.setState({ headerParam: res });
          type = "param";
        }

        const query = new URLSearchParams(this.props.location.search),
          param = query.get("param"),
          subId = query.get("subId");

        if (param !== null) {
          this.setState({ headerParam: encodeURIComponent(param) });
          type = "param";
        }
        if (subId !== null) {
          this.setState({ headerParam: encodeURIComponent(subId) });
          type = "subId";
        }

        if (this.state.headerParam !== null) {
          if (type === "param") {
            userMsisdn = checkHeaderEnrichment(this.state.headerParam);
          } else if (type === "subId") {
            userMsisdn = checkSubId(this.state.headerParam);
          }
          userMsisdn.then((msisdn) => {
            if (msisdn === null) {
              this.setState({ isHeader: true, isLoading: false });
            } else {
              state = checkUserSubscription(msisdn);
              state.then((res) => {
                if (res === true) {
                  this.setState({ isSubscriber: true, isLoading: false });
                  updateUserToken(msisdn);
                } else {
                  this.setState({ isHeader: true, isLoading: false });
                }
              });
            }
          });
        } else {
          this.setState({ isSubscriber: false, isLoading: false });
        }
      });
    }
  }

  render() {
    const { isSubscriber, isLoading, isHeader } = this.state;
    const { component: Component, ...props } = this.props;

    if (isLoading) {
      if (this.props.component.name === "HomeComponent") {
        return <HomeLoader />;
      } else if (
        (this.props.component.name === "GymWorkout" && this.props.loading) ||
        (this.props.component.name === "HomeWorkout" && this.props.loading)
      ) {
        return <HomeLoader />;
      } else if (
        (this.props.component.name === "Questions" && this.props.loading) ||
        (this.props.component.name === "NutritionEpisodes" &&
          this.props.loading) ||
        this.props.component.name === "Profile"
      ) {
        return <HomeLoader />;
      } else if (
        this.props.component.name === "NutritionTips" &&
        this.props.loading
      ) {
        return <HomeLoader />;
      } else if (this.props.component.name === "Ask" && this.props.loading) {
        return <HomeLoader />;
      } else {
        return <HomeLoader />;
      }
    } else {
      return isSubscriber ? (
        <Route {...props} render={(props) => <Component {...props} />} />
      ) : isHeader ? (
        <Redirect to={{ pathname: "/welcome" }} />
      ) : (
        <Redirect to={{ pathname: "/intro" }} />
      );
    }
  }
}

export default withRouter(connect(null, mapDispatchToProps)(MainComponent));
