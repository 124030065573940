import * as ActionTypes from "./ActionTypes";

export const NutritionVideos = (
  state = { isLoading: true, errMess: null, nutritionVideos: [] },
  action
) => {
  switch (action.type) {
    case ActionTypes.ADD_NUTRITION_VIDEOS:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        nutritionVideos: action.payload,
      };

    case ActionTypes.NUTRITION_VIDEOS_LOADING:
      return { ...state, isLoading: true, errMess: null, nutritionVideos: [] };

    case ActionTypes.NUTRITION_VIDEOS_FAILED:
      return { ...state, isLoading: false, errMess: action.payload };

    default:
      return state;
  }
};

export const NutritionTips = (
  state = {
    isLoading: true,
    errMess: null,
    nutritionTips: [],
    pageNo: 0,
  },
  action
) => {
  switch (action.type) {
    case ActionTypes.ADD_NUTRITION_TIPS:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        pageNo: action.currPage,
        maxNo: action.payload.pagesNumber,
        nutritionTips: state.nutritionTips.concat(action.payload.results),
      };

    case ActionTypes.NUTRITION_TIPS_LOADING:
      return { ...state, isLoading: true, errMess: null, nutritionTips: [] };

    case ActionTypes.NUTRITION_TIPS_FAILED:
      return { ...state, isLoading: false, errMess: action.payload };

    default:
      return state;
  }
};

export const AlyMazharTips = (
  state = { isLoading: true, errMess: null, alyMazharTips: [] },
  action
) => {
  switch (action.type) {
    case ActionTypes.ADD_ALYMAZHAR_TIPS:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        alyMazharTips: action.payload,
      };

    case ActionTypes.ALYMAZHAR_TIPS_LOADING:
      return { ...state, isLoading: true, errMess: null, alyMazharTips: [] };

    case ActionTypes.ALYMAZHAR_TIPS_FAILED:
      return { ...state, isLoading: false, errMess: action.payload };

    default:
      return state;
  }
};
