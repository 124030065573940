import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "./UserImgAndRatingComponent.css";

const UserImgAndRatingComponentLoader = () => {
  return (
    <SkeletonTheme color="#202020" highlightColor="#444">
      <div className="d-flex">
        <Skeleton circle={true} width={60} height={60} />
        <div className="mx-2 my-auto">
          <Skeleton count={1} className="m-auto" />
          <Skeleton circle={true} width={20} height={20} className="mx-1" />
          <Skeleton circle={true} width={20} height={20} className="mx-1" />
          <Skeleton circle={true} width={20} height={20} className="mx-1" />
          <Skeleton circle={true} width={20} height={20} className="mx-1" />
          <Skeleton circle={true} width={20} height={20} className="mx-1" />
        </div>
      </div>
    </SkeletonTheme>
  );
};

export default UserImgAndRatingComponentLoader;
