import * as ActionTypes from "./ActionTypes";
import axios from "axios";
import { getUserMsisdn } from "../subscriptionFn";

// **************************************
// ****** START HOME WORKOUTS ***********
// **************************************

export const fetchHomeWorkout = () => (dispatch) => {
  dispatch(homeWorkoutLoading(true));
  let msisdn = getUserMsisdn();

  axios({
    method: "get",
    url:
      process.env.REACT_APP_BASE_URL +
      process.env.REACT_APP_GET_HOME_WORKOUT +
      "/" +
      msisdn,
    headers: {
      "Content-Type": "application/json",
      apiKey: process.env.REACT_APP_API_KEY,
    },
  })
    .then(
      (response) => {
        if (
          response.data.status === 200 &&
          response.data.message === "Success"
        ) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((homeWorkout) => dispatch(addHomeWorkout(homeWorkout.data.results)))

    .catch((error) => dispatch(homeWorkoutFailed(error.message)));
};

export const addHomeWorkout = (homeWorkout) => ({
  type: ActionTypes.ADD_HOME_WORKOUT,
  payload: homeWorkout,
});

export const homeWorkoutLoading = () => ({
  type: ActionTypes.HOME_WORKOUT_LOADING,
});

export const homeWorkoutFailed = (errmess) => ({
  type: ActionTypes.HOME_WORKOUT_FAILED,
  payload: errmess,
});

// **************************************
// ****** START GYM WORKOUTS ************
// **************************************

export const fetchGymWorkout = () => (dispatch) => {
  dispatch(gymWorkoutLoading(true));
  let msisdn = getUserMsisdn();

  axios({
    method: "get",
    url:
      process.env.REACT_APP_BASE_URL +
      process.env.REACT_APP_GET_GYM_WORKOUT +
      "/" +
      msisdn,
    headers: {
      "Content-Type": "application/json",
      apiKey: process.env.REACT_APP_API_KEY,
    },
  })
    .then(
      (response) => {
        if (
          response.data.status === 200 &&
          response.data.message === "Success"
        ) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((gymWorkout) => dispatch(addGymWorkout(gymWorkout.data.results)))

    .catch((error) => dispatch(gymWorkoutFailed(error.message)));
};

export const addGymWorkout = (gymWorkout) => ({
  type: ActionTypes.ADD_GYM_WORKOUT,
  payload: gymWorkout,
});

export const gymWorkoutLoading = () => ({
  type: ActionTypes.GYM_WORKOUT_LOADING,
});

export const gymWorkoutFailed = (errmess) => ({
  type: ActionTypes.GYM_WORKOUT_FAILED,
  payload: errmess,
});

// **************************************
// *** START NUTRITION TIPS & VIDEO *****
// **************************************

export const fetchNutrition =
  (type, page = 1, isLoadMore = false) =>
  (dispatch) => {
    if (!isLoadMore) {
      if (type === "VIDEOS") {
        dispatch(nutritionVideosLoading(true));
      } else if (type === "AMT") {
        dispatch(alyMazharTipsLoading(true));
      } else {
        dispatch(nutritionTipsLoading(true));
      }
    }

    axios({
      method: "get",
      url:
        process.env.REACT_APP_BASE_URL +
        process.env.REACT_APP_GET_NUTRITION_ALL_TIPS +
        "/" +
        type +
        "/" +
        page,
      headers: {
        "Content-Type": "application/json",
        apiKey: process.env.REACT_APP_API_KEY,
      },
    })
      .then(
        (response) => {
          if (
            response.data.status === 200 &&
            response.data.message === "Success"
          ) {
            return response;
          } else {
            var error = new Error(
              "Error " + response.status + ": " + response.statusText
            );
            error.response = response;
            throw error;
          }
        },
        (error) => {
          var errmess = new Error(error.message);
          throw errmess;
        }
      )
      .then((nutrition) => {
        if (type === "VIDEOS") {
          dispatch(addNutritionVideos(nutrition.data.results, page + 1));
        } else if (type === "AMT") {
          dispatch(addAlyMazharTips(nutrition.data.results));
        } else {
          dispatch(addNutritionTips(nutrition.data, page + 1));
        }
      })

      .catch((error) => {
        if (type === "VIDEOS") {
          dispatch(nutritionVideosFailed(error.message));
        } else {
          dispatch(nutritionTipsFailed(error.message));
        }
      });
  };

export const addNutritionVideos = (nutritionVideos) => ({
  type: ActionTypes.ADD_NUTRITION_VIDEOS,
  payload: nutritionVideos,
});

export const nutritionVideosLoading = () => ({
  type: ActionTypes.NUTRITION_VIDEOS_LOADING,
});

export const nutritionVideosFailed = (errmess) => ({
  type: ActionTypes.NUTRITION_VIDEOS_FAILED,
  payload: errmess,
});

export const addNutritionTips = (nutritionTips, currPage) => ({
  type: ActionTypes.ADD_NUTRITION_TIPS,
  payload: nutritionTips,
  currPage: currPage,
});

export const nutritionTipsLoading = () => ({
  type: ActionTypes.NUTRITION_TIPS_LOADING,
});

export const nutritionTipsFailed = (errmess) => ({
  type: ActionTypes.NUTRITION_TIPS_FAILED,
  payload: errmess,
});

export const addAlyMazharTips = (alyMazharTips) => ({
  type: ActionTypes.ADD_ALYMAZHAR_TIPS,
  payload: alyMazharTips,
});

export const alyMazharTipsLoading = () => ({
  type: ActionTypes.ALYMAZHAR_TIPS_LOADING,
});

export const alyMazharTipsFailed = (errmess) => ({
  type: ActionTypes.ALYMAZHAR_TIPS_FAILED,
  payload: errmess,
});

// **************************************
// ************ START FAQ ***************
// **************************************

export const fetchFaq =
  (page = 1) =>
  (dispatch) => {
    dispatch(faqLoading(true));

    axios({
      method: "get",
      url:
        process.env.REACT_APP_BASE_URL +
        process.env.REACT_APP_GET_ALL_FAQ +
        "/" +
        page,
      headers: {
        "Content-Type": "application/json",
        apiKey: process.env.REACT_APP_API_KEY,
      },
    })
      .then(
        (response) => {
          if (
            response.data.status === 200 &&
            response.data.message === "Success"
          ) {
            return response;
          } else {
            var error = new Error(
              "Error " + response.status + ": " + response.statusText
            );
            error.response = response;
            throw error;
          }
        },
        (error) => {
          var errmess = new Error(error.message);
          throw errmess;
        }
      )
      .then((faq) => dispatch(addFaq(faq.data.results)))

      .catch((error) => dispatch(faqFailed(error.message)));
  };

export const addFaq = (faq) => ({
  type: ActionTypes.ADD_FAQ,
  payload: faq,
});

export const faqLoading = () => ({
  type: ActionTypes.FAQ_LOADING,
});

export const faqFailed = (errmess) => ({
  type: ActionTypes.FAQ_FAILED,
  payload: errmess,
});

// **************************************
// ****** START GYM FAVORITES ***********
// **************************************

export const fetchGymFavorites = () => (dispatch) => {
  dispatch(gymFavoritesLoading(true));
  let msisdn = getUserMsisdn();

  axios({
    method: "get",
    url:
      process.env.REACT_APP_BASE_URL +
      process.env.REACT_APP_GET_GYM_FAVORITES +
      "/" +
      msisdn,
    headers: {
      "Content-Type": "application/json",
      apiKey: process.env.REACT_APP_API_KEY,
    },
  })
    .then(
      (response) => {
        if (
          response.data.status === 200 &&
          response.data.message === "Success"
        ) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((gymFav) => dispatch(addGymFavorites(gymFav.data.results)))

    .catch((error) => dispatch(gymFavoritesFailed(error.message)));
};

export const addGymFavorites = (gymFav) => ({
  type: ActionTypes.ADD_GYM_FAVORITES,
  payload: gymFav,
});

export const gymFavoritesLoading = () => ({
  type: ActionTypes.GYM_FAVORITES_LOADING,
});

export const gymFavoritesFailed = (errmess) => ({
  type: ActionTypes.GYM_FAVORITES_FAILED,
  payload: errmess,
});

// **************************************
// ****** START HOME FAVORITES **********
// **************************************

export const fetchHomeFavorites = () => (dispatch) => {
  dispatch(homeFavoritesLoading(true));
  let msisdn = getUserMsisdn();

  axios({
    method: "get",
    url:
      process.env.REACT_APP_BASE_URL +
      process.env.REACT_APP_GET_HOME_FAVORITES +
      "/" +
      msisdn,
    headers: {
      "Content-Type": "application/json",
      apiKey: process.env.REACT_APP_API_KEY,
    },
  })
    .then(
      (response) => {
        if (
          response.data.status === 200 &&
          response.data.message === "Success"
        ) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((homeFav) => dispatch(addHomeFavorites(homeFav.data.results)))

    .catch((error) => dispatch(homeFavoritesFailed(error.message)));
};

export const addHomeFavorites = (homeFav) => ({
  type: ActionTypes.ADD_HOME_FAVORITES,
  payload: homeFav,
});

export const homeFavoritesLoading = () => ({
  type: ActionTypes.HOME_FAVORITES_LOADING,
});

export const homeFavoritesFailed = (errmess) => ({
  type: ActionTypes.HOME_FAVORITES_FAILED,
  payload: errmess,
});

export const updateWorkouts = () => (dispatch) => {
  dispatch(fetchHomeWorkout());
  dispatch(fetchGymWorkout());
};

export const updateFavorite = () => (dispatch) => {
  dispatch(fetchGymFavorites());
  dispatch(fetchHomeFavorites());
};

// **************************************
// ****** START UPLOADED VIDEOS *********
// **************************************
export const fetchUploadedVideos = () => (dispatch) => {
  dispatch(uploadedVideosLoading(true));
  let msisdn = getUserMsisdn();
  axios({
    method: "get",
    url:
      process.env.REACT_APP_BASE_URL +
      process.env.REACT_APP_GET_UPLOADED_VIDEOS +
      "/" +
      msisdn,
    headers: {
      "Content-Type": "application/json",
      apiKey: process.env.REACT_APP_API_KEY,
    },
  })
    .then(
      (response) => {
        if (
          response.data.status === 200 &&
          response.data.message === "Success"
        ) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((uploadedVideos) =>
      dispatch(addUploadedVideos(uploadedVideos.data.results))
    )

    .catch((error) => dispatch(uploadedVideosFailed(error.message)));
};

export const addUploadedVideos = (uploadedVideos) => ({
  type: ActionTypes.ADD_UPLOADED_VIDEO,
  payload: uploadedVideos,
});

export const uploadedVideosLoading = () => ({
  type: ActionTypes.UPLOADED_VIDEO_LOADING,
});

export const uploadedVideosFailed = (errmess) => ({
  type: ActionTypes.UPLOADED_VIDEO_FAILED,
  payload: errmess,
});

// **************************************
// ************ START ASK  **************
// **************************************

export const fetchIsAsked = () => (dispatch) => {
  dispatch(isAskedLoading(true));
  let msisdn = getUserMsisdn();
  axios({
    method: "post",
    url: process.env.REACT_APP_BASE_URL + process.env.REACT_APP_IS_ASKED,
    headers: {
      "Content-Type": "application/json",
      apiKey: process.env.REACT_APP_API_KEY,
    },
    params: {
      mobileNumber: msisdn,
    },
  })
    .then(
      (response) => {
        if (
          response.data.status === 200 &&
          response.data.message === "Success"
        ) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((isAsked) => dispatch(addisAsked(isAsked.data.asked)))

    .catch((error) => dispatch(isAskedFailed(error.message)));
};

export const addisAsked = (isAsked) => ({
  type: ActionTypes.ADD_IS_ASKED,
  payload: isAsked,
});

export const isAskedLoading = () => ({
  type: ActionTypes.IS_ASKED_LOADING,
});

export const isAskedFailed = (errmess) => ({
  type: ActionTypes.IS_ASKED_FAILED,
  payload: errmess,
});

// **************************************
// ****** START COMPETITION HEADER ******
// **************************************
export const fetchCompetitionHeader = () => (dispatch) => {
  dispatch(competitionHeaderLoading(true));
  axios({
    method: "GET",
    url:
      process.env.REACT_APP_BASE_URL +
      process.env.REACT_APP_GET_COMPETITION_HEADER,
    headers: {
      "Content-Type": "application/json",
      apiKey: process.env.REACT_APP_API_KEY,
    },
  })
    .then(
      (response) => {
        if (
          response.data.status === 200 &&
          response.data.message === "Success"
        ) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((competitionHeader) =>
      dispatch(addCompetitionHeader(competitionHeader.data.results))
    )

    .catch((error) => dispatch(competitionHeaderFailed(error.message)));
};

export const addCompetitionHeader = (competitionHeader) => ({
  type: ActionTypes.ADD_COMPETITION_HEADER,
  payload: competitionHeader,
});

export const competitionHeaderLoading = () => ({
  type: ActionTypes.COMPETITION_HEADER_LOADING,
});

export const competitionHeaderFailed = (errmess) => ({
  type: ActionTypes.COMPETITION_HEADER_FAILED,
  payload: errmess,
});

// **************************************
// ****** START GET COMPETITION *********
// **************************************

export const fetchCompetition = () => (dispatch) => {
  dispatch(competitionLoading(true));

  let msisdn = getUserMsisdn();
  axios({
    method: "GET",
    url:
      process.env.REACT_APP_BASE_URL +
      process.env.REACT_APP_GET_COMPETITION +
      `/${msisdn}`,
    headers: {
      "Content-Type": "application/json",
      apiKey: process.env.REACT_APP_API_KEY,
    },
  })
    .then(
      (response) => {
        if (
          response.data.status === 200 &&
          response.data.message === "Success"
        ) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((competitions) => dispatch(addCompetition(competitions.data.results)))

    .catch((error) => dispatch(competitionFailed(error.message)));
};

export const addCompetition = (competitions) => ({
  type: ActionTypes.ADD_COMPETITION,
  payload: competitions,
});

export const competitionLoading = () => ({
  type: ActionTypes.COMPETITION_LOADING,
});

export const competitionFailed = (errmess) => ({
  type: ActionTypes.COMPETITION_FAILED,
  payload: errmess,
});

// **************************************
// ****** START COMPETITION USERS *******
// **************************************

export const fetchCompetitionUsers = () => (dispatch) => {
  dispatch(competitionUsersLoading(true));
  let msisdn = getUserMsisdn();
  axios({
    method: "GET",
    url:
      process.env.REACT_APP_BASE_URL +
      process.env.REACT_APP_GET_USERS_COMPETITION_VIDEO +
      `/${msisdn}`,
    headers: {
      "Content-Type": "application/json",
      apiKey: process.env.REACT_APP_API_KEY,
    },
  })
    .then(
      (response) => {
        if (
          response.data.status === 200 &&
          response.data.message === "Success"
        ) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((compUsers) => dispatch(addCompetitionUsers(compUsers.data.results)))

    .catch((error) => dispatch(competitionUsersFailed(error.message)));
};

export const addCompetitionUsers = (compUsers) => ({
  type: ActionTypes.ADD_COMPETITIONUSERS,
  payload: compUsers,
});

export const competitionUsersLoading = () => ({
  type: ActionTypes.COMPETITIONUSERS_LOADING,
});

export const competitionUsersFailed = (errmess) => ({
  type: ActionTypes.COMPETITIONUSERS_FAILED,
  payload: errmess,
});

// **************************************
// ***** START COMPETITION RANKING ******
// **************************************

export const fetchCompetitionRanking = () => (dispatch) => {
  dispatch(competitionRankingLoading(true));
  axios({
    method: "GET",
    url:
      process.env.REACT_APP_BASE_URL + process.env.REACT_APP_GET_USERS_RANKING,
    headers: {
      "Content-Type": "application/json",
      apiKey: process.env.REACT_APP_API_KEY,
    },
  })
    .then(
      (response) => {
        if (
          response.data.status === 200 &&
          response.data.message === "Success"
        ) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((compRanking) =>
      dispatch(addCompetitionRanking(compRanking.data.results))
    )

    .catch((error) => dispatch(competitionRankingFailed(error.message)));
};

export const addCompetitionRanking = (compRanking) => ({
  type: ActionTypes.ADD_COMPRANKING,
  payload: compRanking,
});

export const competitionRankingLoading = () => ({
  type: ActionTypes.COMPRANKING_LOADING,
});

export const competitionRankingFailed = (errmess) => ({
  type: ActionTypes.COMPRANKING_FAILED,
  payload: errmess,
});

// **************************************
// ********** START ADD DONE ************
// **************************************

export const addDone = (video) => (dispatch) => {
  if (video.workoutType === "gym") {
    dispatch(updateGymDoneLoading(true));
  } else {
    dispatch(updateHomeDoneLoading(true));
  }

  if (video.done) {
    axios({
      method: "post",
      url: process.env.REACT_APP_BASE_URL + process.env.REACT_APP_REMOVE_DONE,
      headers: {
        "Content-Type": "application/json",
        apiKey: process.env.REACT_APP_API_KEY,
      },
      params: {
        workoutId: video.id,
        msisdn: getUserMsisdn(),
      },
    })
      .then(
        (response) => {
          if (response.data.status === 200) {
            return response;
          } else {
            var error = new Error(
              "Error " + response.status + ": " + response.statusText
            );
            error.response = response;
            throw error;
          }
        },
        (error) => {
          var errmess = new Error(error.message);
          throw errmess;
        }
      )
      .then(
        video.workoutType === "gym"
          ? () => dispatch(updateGymDone(video.id))
          : () => dispatch(updateHomeDone(video.id))
      );
  } else {
    axios({
      method: "post",
      url: process.env.REACT_APP_BASE_URL + process.env.REACT_APP_ADD_DONE,
      headers: {
        "Content-Type": "application/json",
        apiKey: process.env.REACT_APP_API_KEY,
      },
      params: {
        workoutId: video.id,
        msisdn: getUserMsisdn(),
      },
    })
      .then(
        (response) => {
          if (response.data.status === 200) {
            return response;
          } else {
            var error = new Error(
              "Error " + response.status + ": " + response.statusText
            );
            error.response = response;
            throw error;
          }
        },
        (error) => {
          var errmess = new Error(error.message);
          throw errmess;
        }
      )
      .then(
        video.workoutType === "gym"
          ? () => dispatch(updateGymDone(video.id))
          : () => dispatch(updateHomeDone(video.id))
      );
  }
};

export const updateHomeDone = (videoId) => ({
  type: ActionTypes.UPDATE_HOME_DONE,
  payload: videoId,
});

export const updateGymDone = (videoId) => ({
  type: ActionTypes.UPDATE_GYM_DONE,
  payload: videoId,
});

export const updateHomeDoneLoading = () => ({
  type: ActionTypes.UPDATE_HOME_DONE_LOADING,
});

export const updateGymDoneLoading = () => ({
  type: ActionTypes.UPDATE_GYM_DONE_LOADING,
});

// **************************************
// ******** START ADD FAVORITE **********
// **************************************

export const handleFavorite = (video) => (dispatch) => {
  if (video.workoutType === "gym") {
    dispatch(updateGymWorkoutFavLoading(true));
  } else {
    dispatch(updateHomeWorkoutFavLoading(true));
  }

  if (video.favorite) {
    axios({
      method: "post",
      url:
        process.env.REACT_APP_BASE_URL + process.env.REACT_APP_REMOVE_FAVORITE,
      headers: {
        "Content-Type": "application/json",
        apiKey: process.env.REACT_APP_API_KEY,
      },
      params: {
        workoutId: video.id,
        msisdn: getUserMsisdn(),
      },
    })
      .then(
        (response) => {
          if (response.data.status === 200) {
            return response;
          } else {
            var error = new Error(
              "Error " + response.status + ": " + response.statusText
            );
            error.response = response;
            throw error;
          }
        },
        (error) => {
          var errmess = new Error(error.message);
          throw errmess;
        }
      )
      .then(
        video.workoutType === "gym"
          ? () => dispatch(updateGymFavoriteRemove(video))
          : () => dispatch(updateHomeFavoriteRemove(video))
      )
      .then(
        video.workoutType === "gym"
          ? () => dispatch(updateGymWorkoutFav(video.id))
          : () => dispatch(updateHomeWorkoutFav(video.id))
      );
  } else {
    axios({
      method: "post",
      url: process.env.REACT_APP_BASE_URL + process.env.REACT_APP_ADD_FAVORITE,
      headers: {
        "Content-Type": "application/json",
        apiKey: process.env.REACT_APP_API_KEY,
      },
      params: {
        workoutId: video.id,
        msisdn: getUserMsisdn(),
      },
    })
      .then(
        (response) => {
          if (response.data.status === 200) {
            return response;
          } else {
            var error = new Error(
              "Error " + response.status + ": " + response.statusText
            );
            error.response = response;
            throw error;
          }
        },
        (error) => {
          var errmess = new Error(error.message);
          throw errmess;
        }
      )
      .then(
        video.workoutType === "gym"
          ? () => dispatch(updateGymFavoriteAdd(video))
          : () => dispatch(updateHomeFavoriteAdd(video))
      )
      .then(
        video.workoutType === "gym"
          ? () => dispatch(updateGymWorkoutFav(video.id))
          : () => dispatch(updateHomeWorkoutFav(video.id))
      );
  }
};

export const updateHomeFavoriteAdd = (video) => ({
  type: ActionTypes.UPDATE_HOME_FAVORITE_ADD,
  payload: video,
});

export const updateHomeFavoriteRemove = (video) => ({
  type: ActionTypes.UPDATE_HOME_FAVORITE_REMOVE,
  payload: video,
});

export const updateGymFavoriteAdd = (video) => ({
  type: ActionTypes.UPDATE_GYM_FAVORITE_ADD,
  payload: video,
});

export const updateGymFavoriteRemove = (video) => ({
  type: ActionTypes.UPDATE_GYM_FAVORITE_REMOVE,
  payload: video,
});

export const updateHomeFavoriteLoading = () => ({
  type: ActionTypes.UPDATE_HOME_FAVORITE_LOADING,
});

export const updateGymFavoriteLoading = () => ({
  type: ActionTypes.UPDATE_GYM_FAVORITE_LOADING,
});

export const updateGymWorkoutFav = (videoId) => ({
  type: ActionTypes.UPDATE_GYM_WORKOUTS_FAVORITE,
  payload: videoId,
});
export const updateHomeWorkoutFav = (videoId) => ({
  type: ActionTypes.UPDATE_HOME_WORKOUTS_FAVORITE,
  payload: videoId,
});

export const updateGymWorkoutFavLoading = () => ({
  type: ActionTypes.UPDATE_GYM_WORKOUTS_FAVORITE_LOADING,
});

export const updateHomeWorkoutFavLoading = () => ({
  type: ActionTypes.UPDATE_HOME_WORKOUTS_FAVORITE_LOADING,
});

// **************************************
// ****** START UPDATE PROFILE **********
// **************************************

export const fetchUserProfile =
  (image = null, username = null, email = null, password = null) =>
  (dispatch) => {
    dispatch(userProfileLoading(true));

    let msisdn = getUserMsisdn(),
      form_data = new FormData();

    form_data.append("msisdn", msisdn);
    form_data.append("image", image);
    form_data.append("username", username);
    form_data.append("email", email);
    form_data.append("password", password);
    axios
      .post(
        process.env.REACT_APP_BASE_URL +
          process.env.REACT_APP_UPDATE_USE_ACCOUNT,
        form_data,
        {
          headers: {
            "content-type": "multipart/form-data",
            apiKey: process.env.REACT_APP_API_KEY,
          },
        }
      )
      .then(
        (response) => {
          if (
            response.data.status === 200 &&
            response.data.message === "Success"
          ) {
            return response;
          } else {
            var error = new Error(
              "Error " + response.status + ": " + response.statusText
            );
            error.response = response;
            throw error;
          }
        },
        (error) => {
          var errmess = new Error(error.message);
          throw errmess;
        }
      )
      .then((userProfile) => dispatch(addUserProfile(userProfile.data.resluts)))

      .catch((error) => dispatch(userProfileFailed(error.message)));
  };

export const addUserProfile = (userProfile) => ({
  type: ActionTypes.ADD_UPDATE_PROFILE,
  payload: userProfile,
});

export const userProfileLoading = () => ({
  type: ActionTypes.UPDATE_PROFILE_LOADING,
});

export const userProfileFailed = (errmess) => ({
  type: ActionTypes.UPDATE_PROFILE_FAILED,
  payload: errmess,
});

// **************************************
// ********* START ADD RATE *************
// **************************************

export const addUserRate = (challengeId, vote) => (dispatch) => {
  dispatch(rateLoading(true));
  let msisdn = getUserMsisdn();
  axios({
    method: "post",
    url: process.env.REACT_APP_BASE_URL + process.env.REACT_APP_ADD_RATE,
    headers: {
      "Content-Type": "application/json",
      apiKey: process.env.REACT_APP_API_KEY,
    },
    params: {
      msisdn: msisdn,
      challengeId: challengeId,
      vote: vote,
    },
  })
    .then(
      (response) => {
        if (
          response.data.status === 200 &&
          response.data.message === "Success"
        ) {
          return response;
        } else {
          var error = new Error(
            "Error " + response.status + ": " + response.statusText
          );
          error.response = response;
          throw error;
        }
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      },
      (error) => {
        var errmess = new Error(error.message);
        throw errmess;
      }
    )
    .then((res) => dispatch(addRate(res.data)))
    .then(() =>
      setTimeout(function () {
        window.location.reload();
      }, 1500)
    )

    .catch((error) => dispatch(rateFailed(error.message)));
};

export const addRate = (res) => ({
  type: ActionTypes.ADD_RATE,
  payload: res,
});

export const rateLoading = () => ({
  type: ActionTypes.RATE_LOADING,
});

export const rateFailed = (errmess) => ({
  type: ActionTypes.RATE_FAILED,
  payload: errmess,
});
