import React from "react";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { BiRefresh } from "react-icons/bi";
import { Button } from "react-bootstrap";
import "./ShareBtnComponent.css";

const ReplaceBtnComponent = (props) => {
  const { i18n } = useTranslation();

  if (!props.trials) {
    return (
      <Button
        className="secondary-btn fs-xs d-block m-auto w-100"
        disabled={true}
      >
        <BiRefresh
          className={`fs-xxm ${i18n.language === "en" ? " mr-1" : "ml-1"}`}
        />
        <Trans i18nKey="btn.replace" />
      </Button>
    );
  } else {
    return (
      <Button
        as={Link}
        to={`/upload/${props.challengeId}/${props.trials}`}
        className="secondary-btn fs-xs d-block m-auto w-100 "
      >
        <BiRefresh
          className={`fs-xxm ${i18n.language === "en" ? " mr-1" : "ml-1"}`}
        />
        <Trans i18nKey="btn.replace" />
      </Button>
    );
  }
};

export default ReplaceBtnComponent;
