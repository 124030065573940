import React from "react";
import { Container, Col } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function AskLoader() {
  return (
    <Container className="my-5 askWeb">
      <SkeletonTheme color="#202020" highlightColor="#444">
        <Col xs={12} md={5} className="m-auto text-center p-0">
          <Skeleton circle={true} height={150} width={150} className="m-auto" />
          <h5 className="text-uppercase fs-22 color-7EBD19 text-center my-3">
            <Skeleton count={1} width={200} />
          </h5>
          <p className="text-center fs-16 color-656565">
            <Skeleton count={1} width={300} />
          </p>
          <p className="color-ffffff fs-14 text-center">
            <Skeleton count={1} width={300} />
          </p>
          <Skeleton height={200} />
          <p className="fs-14 color-656565 text-center mt-2">
            <Skeleton count={1} width={300} />
          </p>
          <Skeleton height={50} width={200} className="d-block m-auto" />
          <Skeleton width={100} />
        </Col>
      </SkeletonTheme>
    </Container>
  );
}
