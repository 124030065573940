import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Seo from "../components/Seo";
import NavbarWeb2Component from "../components/navbar/NavbarWeb2Component";
import NavbarMob2Component from "../components/navbar/NavbarMob2Component";
import BannerWebComponent from "../components/banner/BannerWebComponent";
import BannerMobComponent from "../components/banner/BannerMobComponent";
import WorkoutWebComponent from "../components/workouts/WorkoutWebComponent";
import WorkoutMobComponent from "../components/workouts/WorkoutMobComponent";
import FooterComponent from "../components/footer/FooterComponent";
import { addDone, handleFavorite } from "../redux/ActionCreators";

const mapStateToProps = (state) => {
  return {
    homeWorkout: state.homeWorkout,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addDone: (video) => dispatch(addDone(video)),
  handleFavorite: (video) => dispatch(handleFavorite(video)),
});

const HomeWorkout = (props) => {
  const { t } = useTranslation();

  return (
    <div>
      <Seo
        title={` ${t("title")} | ${t("sections.section1")}`}
        description={t("sections.description1")}
        pathSlug="home-workout"
      />

      <div className="d-none d-xl-block">
        <NavbarWeb2Component />
        <BannerWebComponent
          show={true}
          title={<Trans i18nKey="sections.section1" />}
          subTitle={<Trans i18nKey="sections.description1" />}
        />
        <WorkoutWebComponent
          progressBar={props.homeWorkout.progressBar}
          addDone={props.addDone}
          isLoading={props.homeWorkout.isLoading}
          errMess={props.homeWorkout.errMess}
          videos={props.homeWorkout.homeWorkout}
          doneLoading={props.homeWorkout.doneLoading}
          videoId={props.homeWorkout.videoId}
          handleFavorite={props.handleFavorite}
          favLoading={props.homeWorkout.favLoading}
          initialVideo={
            props.homeWorkout.homeWorkout.length > 0
              ? props.homeWorkout.homeWorkout.filter(
                  (video) => video.type === "workout"
                )[0]
              : null
          }
        />
      </div>
      <div className="d-xl-none">
        <NavbarMob2Component />
        <BannerMobComponent
          show={true}
          title={<Trans i18nKey="sections.section1" />}
          subTitle={<Trans i18nKey="sections.description1" />}
        />
        <WorkoutMobComponent
          progressBar={props.homeWorkout.progressBar}
          addDone={props.addDone}
          isLoading={props.homeWorkout.isLoading}
          errMess={props.homeWorkout.errMess}
          videos={props.homeWorkout.homeWorkout}
          doneLoading={props.homeWorkout.doneLoading}
          handleFavorite={props.handleFavorite}
          favLoading={props.homeWorkout.favLoading}
        />
      </div>
      <FooterComponent />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeWorkout);
