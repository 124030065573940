import * as ActionTypes from "./ActionTypes";

export const Faq = (
  state = { isLoading: true, errMess: null, faq: [] },
  action
) => {
  switch (action.type) {
    case ActionTypes.ADD_FAQ:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        faq: action.payload,
      };

    case ActionTypes.FAQ_LOADING:
      return { ...state, isLoading: true, errMess: null, faq: [] };

    case ActionTypes.FAQ_FAILED:
      return { ...state, isLoading: false, errMess: action.payload };

    default:
      return state;
  }
};

export const IsAsked = (
  state = { isLoading: true, errMess: null, isAsked: [] },
  action
) => {
  switch (action.type) {
    case ActionTypes.ADD_IS_ASKED:
      return {
        ...state,
        isLoading: false,
        errMess: null,
        isAsked: action.payload,
      };

    case ActionTypes.IS_ASKED_LOADING:
      return { ...state, isLoading: true, errMess: null, isAsked: [] };

    case ActionTypes.IS_ASKED_FAILED:
      return { ...state, isLoading: false, errMess: action.payload };

    default:
      return state;
  }
};
