import React from "react";
import { BsHeart, BsHeartFill } from "react-icons/bs";
import SpinnerLoader from "../loaders/SpinnerLoader";
import "./iconsStyle.css";

const FavIconComponent = ({ isFav, favLoading }) => {
  return (
    <div className="favContainer ml-auto">
      {favLoading ? (
        <SpinnerLoader />
      ) : isFav ? (
        <BsHeartFill className="m-2" />
      ) : (
        <BsHeart className="m-2" />
      )}
    </div>
  );
};

export default FavIconComponent;
