import React from "react";
import { Trans } from "react-i18next";
import { Container } from "react-bootstrap";
import "./CompetitonTitleComponent.css";

const CompetitonTitleComponent = (props) => {
  return (
    <Container className="mt-4 mb-2 comp-title-container">
      <p className="fs-xm color-primary-light text-center text-uppercase m-auto">
        <Trans i18nKey={props.text} />
      </p>
    </Container>
  );
};

export default CompetitonTitleComponent;
