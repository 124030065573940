import moment from "moment";
export function convertDate(date, lang) {
  const daysEn = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    monthsEn = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    monthsAr = [
      "يناير",
      "فبراير",
      "مارس",
      "إبريل",
      "مايو",
      "يونيو",
      "يوليو",
      "أغسطس",
      "سبتمبر",
      "أكتوبر",
      "نوفمبر",
      "ديسمبر",
    ],
    daysAr = [
      "اﻷحد",
      "اﻷثنين",
      "الثلاثاء",
      "اﻷربعاء",
      "الخميس",
      "الجمعة",
      "السبت",
    ],
    day = moment(date).format("DD"),
    year = moment(date).year();
  if (lang === "en") {
    return `${daysEn[moment(date).day()]}, ${day} ${
      monthsEn[moment(date).month()]
    } ${year} `;
  } else if (lang === "ar") {
    return `${daysAr[moment(date).day()]}, ${day} ${
      monthsAr[moment(date).month()]
    } ${year} `;
  }
}

export function countDown(date, pos) {
  var now = moment(new Date()), //todays date
    end = moment("2021-05-12 12:00:00"), // another date
    duration = moment.duration(end.diff(now)),
    days = duration.asDays().toFixed(),
    digits = days.toString().split("");

  if (duration < 0) {
    digits.unshift("0", "0");
  }

  if (digits.length === 1) {
    digits.unshift("0");
  }
  return digits[pos];
}
