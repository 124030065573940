import React from "react";
import { Image } from "react-bootstrap";
import StarsComponent from "../icons/StarsComponent";
import { useTranslation } from "react-i18next";
import "./UserImgAndRatingComponent.css";

const UserImgAndRatingComponent = (props) => {
  const { t } = useTranslation();
  return (
    <div className="d-flex">
      <Image
        src={typeof props.userImg ? "/img/profile.png" : props.userImg}
        className="user-img"
      />
      <div className="mx-2">
        <h4 className="fs-md color-tertiary-light mr-auto my-auto">
          {props.userName && props.userName !== "null"
            ? props.userName
            : t("userForm.username") + props.userId}
        </h4>
        <StarsComponent userRate={props.userRate} />
      </div>
    </div>
  );
};

export default UserImgAndRatingComponent;
