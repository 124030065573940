import { Container, Image, Navbar, NavDropdown, Nav } from "react-bootstrap";
import React from "react";
import { Link, NavLink } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import LangComponent from "./language/LangComponent";
import { Trans } from "react-i18next";
import "./NavbarWeb2Component.css";

const NavbarWeb2Component = () => {
  return (
    <Container fluid className="px-0 navbar_2 bg-secondary">
      <Container>
        <Navbar collapseOnSelect expand="lg">
          <Navbar.Brand to="/" className="m-auto">
            <Image src="/img/fitness-logo-2.png" className="navbar-logo img-fluid " />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav>
              <NavLink to="/" className="fs-sm my-auto mx-2" exact={true}>
                <Trans i18nKey="navbar.home" />
              </NavLink>
              <NavLink to="/gym-workout" className="fs-sm my-auto mx-2">
                <Trans i18nKey="sections.section2" />
              </NavLink>
              <NavLink to="/home-workout" className="fs-sm my-auto mx-2">
                <Trans i18nKey="sections.section1" />
              </NavLink>
              <NavLink to="/aly-mazhar-tips" className="fs-sm my-auto mx-2">
                <Trans i18nKey="sections.section7" />
              </NavLink>
              <NavLink to="/ask" className="fs-sm my-auto mx-2">
                <Trans i18nKey="sections.section3" />
              </NavLink>
              <NavLink to="/questions" className="fs-sm my-auto mx-2">
                <Trans i18nKey="sections.section4" />
              </NavLink>
              <NavDropdown
                title={<Trans i18nKey="sections.section5" />}
                id="collasible-nav-dropdown"
                className="fs-sm"
              >
                <NavDropdown.Item
                  as={Link}
                  to="/nutrition-tips"
                  className="fs-sm"
                >
                  <Trans i18nKey="navbar.Tips" />
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to="/nutrition-episodes"
                  className="fs-sm"
                >
                  <Trans i18nKey="navbar.Eposides" />
                </NavDropdown.Item>
              </NavDropdown>
              {/* <NavDropdown
                title={<Trans i18nKey="navbar.competition" />}
                id="collasible-nav-dropdown"
                className="fs-md"
              >
                <NavDropdown.Item as={Link} to="/challenges" className="fs-md">
                  <Trans i18nKey="navbar.challenges" />
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/ranking" className="fs-md">
                  <Trans i18nKey="navbar.competition ranking" />
                </NavDropdown.Item>
              </NavDropdown> */}
              <NavLink to="/profile" as={Link} className="fs-md my-auto mx-2">
                <CgProfile className="fs-xl" />
              </NavLink>
              <LangComponent />
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </Container>
  );
};
export default NavbarWeb2Component;
