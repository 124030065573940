import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { convertDate } from "../../globalFn";

import { Trans } from "react-i18next";

const BannerMobComponent = (props) => {
  var today = new Date();
  return (
    <Container className="my-4" fluid>
      <Col>
        <Row className="">
          <Col xs={12} className="p-0 mb-3">
            <h4 className="color-primary-light text-uppercase fs-xxm text-center">
              {props.title}
            </h4>
          </Col>
          <Col>
            {props.show ? (
              <p className="fs-sm color-656565 text-center m-0">
                <Trans i18nKey="banner.title" />
              </p>
            ) : null}
            <p className="fs-xs color-656565 mx-1 text-center m-0">
              {convertDate(today, props.lang)}
            </p>
            <p className="fs-xs text-muted text-center m-0">{props.subTitle}</p>
          </Col>
        </Row>
      </Col>
    </Container>
  );
};

export default BannerMobComponent;
